/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
// @material-ui/icons
// core components
import cardHeaderStyle from '../assets/jss/material-dashboard-pro-react/components/cardHeaderStyle'
import { compose } from 'recompose'
type CardHeaderProps = {
  className: string
  color?: 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose'
  plain?: boolean
  image?: boolean
  contact?: boolean
  signup?: boolean
  stats?: boolean
  icon?: boolean
  text?: boolean
}

type CardHeaderInjectedProps = WithStyles<typeof cardHeaderStyle>

const CardHeader: React.SFC<CardHeaderProps & CardHeaderInjectedProps> = ({ ...props }) => {
  const {
    classes,
    className,
    children,
    color,
    plain,
    image,
    contact,
    signup,
    stats,
    icon,
    text,
    ...rest
  } = props
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [classes[color + 'CardHeader']]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderImage]: image,
    [classes.cardHeaderContact]: contact,
    [classes.cardHeaderSignup]: signup,
    [classes.cardHeaderStats]: stats,
    [classes.cardHeaderIcon]: icon,
    [classes.cardHeaderText]: text,
    [className]: className !== undefined,
  })
  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  )
}

export default compose<CardHeaderInjectedProps & CardHeaderProps, Partial<CardHeaderProps>>(
  withStyles(cardHeaderStyle)
)(CardHeader)
