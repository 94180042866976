import {
  filter,
  uniqWith,
  sortBy,
  prop,
  map,
  pipe,
  values,
  pathEq,
  sum,
  find,
  all,
  uniq,
} from 'ramda'
import { RootState } from '../../store'
import { Company } from './types'
import { getProjects } from '../projects/selectors'
import { Project } from '../projects/types'
import { CompanyList, Prediction } from './types'

export const getCompanies = (state: RootState): CompanyList => {
  return state.companies.detailsById
}

const getCompaniesOfProject = (companies: CompanyList) => (project: Project): Company =>
  companies[project.customer.id]

const filterParentCompany = (companies: Company[]) =>
  filter((company: Company) => {
    return company.identifier !== 'K0001'
  })(companies)

const uniqCompany: (companies: Company[]) => Company[] = uniqWith(
  (c: Company, b: Company) => c.id === b.id
)

const sortCompany: (companies: Company[]) => Company[] = sortBy(prop('name'))

export const getCustomers = (state: RootState, withoutOwnCompany = false): Company[] => {
  const companies = getCompanies(state)
  const projects = getProjects(state)

  const getCompanyOfProject = getCompaniesOfProject(companies)

  const customers: CompanyList = map(getCompanyOfProject)(projects)

  if (withoutOwnCompany) {
    return pipe(
      uniqCompany,
      filterParentCompany,
      sortCompany
    )(values(customers))
  }
  return pipe(
    uniqCompany,
    sortCompany
  )(values(customers))
}

export const getCompany = (state: RootState, id: number): Company => {
  return getCompanies(state)[id]
}

export const getCompanyOfComaticId = (state: RootState, comaticId: string): Company => {
  const companies = values(getCompanies(state))
  const getRelCompanies: (companies: Company[]) => Company[] = filter(
    pathEq(['custom_properties', 'ComaticId'], comaticId)
  )

  const relCompanies: Company[] = getRelCompanies(companies)
  return relCompanies[0]
}

export const getCompanyOfAdvertizerId = (state: RootState, comaticId: string): Company => {
  const companies = values(getCompanies(state))
  const getRelCompanies: (companies: Company[]) => Company[] = filter(
    pathEq(['custom_properties', 'AdvertizerId'], comaticId)
  )

  const relCompanies: Company[] = getRelCompanies(companies)
  return relCompanies[0]
}

export const getPredictionTotal = (predictions: Prediction[] | undefined) => {
  if (predictions) {
    const nums: number[] = map(prop('value'))(predictions)
    return sum(nums)
  }
  return 0
}

export const getStatRelevantCompanies = (state: RootState): CompanyList => {
  const companies = getCompanies(state)
  const projects = getProjects(state)

  // const relevantCompanies: CompanyList = {}

  const relevantCompanies: Company[] = filter<Company>(company => {
    // filter the companies with projects
    return find(pathEq(['customer', 'id'], company.id))(values(projects)) ? true : false
  })(values(companies))

  /*
  forEachObjIndexed((project: Project) => {
    relevantCompanies[project.customer.id] = companies[project.customer.id]
  })(projects as Dictionary<Project>)
  */

  return relevantCompanies.reduce<CompanyList>((accu, c) => {
    return {
      ...accu,
      c,
    }
  }, {})
}

export const hasCompanyLabels = (labels: string[]) => (company: Company): boolean => {
  const hasLabel = (label: string): boolean => label === '' || company.labels.indexOf(label) > -1

  return all(hasLabel)(labels)
}

export const getCompaniesLabels = (companies: CompanyList): string[] => {
  const labels = values(companies).reduce((labels: string[], company: Company) => {
    return [...labels, ...company.labels]
  }, [])
  return uniq(labels)
}

export default {
  getCompanies,
  getCompany,
  getCustomers,
}
