import React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter as Router, Route } from 'react-router-dom'
import App from './App'
import './index.css'
import store from './state/mockStore'
import { IntlProvider, addLocaleData } from 'react-intl'
import * as de from 'react-intl/locale-data/de'
import moment from 'moment'
import 'moment/locale/de-ch'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { theme } from './theme/default'
import { withTracker } from './page/demo/withTracker'

addLocaleData(de)
moment.locale('de-ch')

ReactDOM.render(
  <IntlProvider locale="de-CH">
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Router>
          <Route
            component={withTracker(App as any, {
              /* additional attributes */
            })}
          />
        </Router>
      </MuiThemeProvider>
    </Provider>
  </IntlProvider>,
  document.getElementById('root') as HTMLElement
)
