import { filter, allPass, find, propEq } from 'ramda'
// import moment from 'moment'
import { RootState } from '../../store'
import {
  // getProjects,
  hasLabels,
  // mapToExtendedProjects,
  ExtendedProject,
  // isInvoiceBetweenDates,
  isArchived,
  isActive,
} from '../projects/selectors'
import { Moment } from 'moment'
// import { getActivities, getActivitiesBetweenDates } from '../activities/selectors'
/*import {
  getInvoiceMaxDate,
  getInvoices,
  getInvoicesTotal,
  getMostProgressedStatus,
} from '../invoices/selectors'*/
import { hasCompanyLabels } from '../companies/selectors'
// import { getExpenses } from '../expenses/selectors'
import { Activity } from '../activities/types'
// import { getOffers } from '../offers/selectors'
// import { getSchedules, getSchedulesBetweenDates } from '../schedules/selectors'
import { Project } from '../projects/types'
import { User } from '../users/types'
import { Company } from '../companies/types'

/* Performance optimization, do not use reduce for performance reason */
/* const convertIdList = <T extends { id: number }>(
  entities: T[]
): { detailsById: { [key: number]: T } } => {
  const result = { detailsById: {} }
  entities.forEach((entity: T) => {
    result.detailsById[entity.id] = entity
  })
  return result

  /* return {
    detailsById: reduce((result, entity: T) => {
      return {
        ...result,
        [entity.id]: entity,
      }
    }, {})(entities),
  }
 } */

export enum ProjectTypeFilter {
  archived = 'archived',
  active = 'active',
  all = 'all',
}

export type FilteredState = {
  filteredState: RootState
  filteredByLabelState: RootState
  filteredByInvoiceDate: ExtendedProject[]
  unfiltered: RootState
}

export type StatsStrategy = 'hours' | 'amount'

export type StatsDuration = 'month' | 'day' | 'week' | 'quarter'

export type DataFilter = {
  customerLabels: string[]
  labels: string[]
  startDate: Moment | null
  endDate: Moment | null
  projectType: ProjectTypeFilter
  showStatsIn: StatsStrategy
  duration: StatsDuration
}

export const filterProjectsByLabels = (labels: string[]) => (projects: Project[]): Project[] => {
  return filter<Project>(project => {
    return allPass([hasLabels(labels)])(project)
  })(projects)
}

export const filterProjectsByCompanies = (companies: Company[]) => (
  projects: Project[]
): Project[] => {
  return filter<Project>(project => {
    const isProjectFromCompanies = find<Company>(propEq('id', project.customer.id))(companies)
    return isProjectFromCompanies ? true : false
  })(projects)
}

export const filterActivitiesByProjects = (
  filteredProjects: Project[],
  activities: Activity[]
): Activity[] => {
  return filter<Activity>(activitiy => {
    const isActivityInProject = find<Project>(propEq('id', activitiy.project.id))(filteredProjects)
    return isActivityInProject ? true : false
  })(activities)
}

export const filterActivitiesByCustomers = (
  filteredCustomers: Company[],
  activities: Activity[]
): Activity[] => {
  return filter<Activity>(activitiy => {
    const isActivityFromCustomer = find<Company>(propEq('id', activitiy.customer.id))(
      filteredCustomers
    )
    return isActivityFromCustomer ? true : false
  })(activities)
}

export const filterActivitiesByEmployee = (
  filteredEmployees: User[],
  activities: Activity[]
): Activity[] => {
  return filter<Activity>(activitiy => {
    const isActivityOfEmployee = find<User>(propEq('id', activitiy.user.id))(filteredEmployees)
    return isActivityOfEmployee ? true : false
  })(activities)
}

export const filterProjectsByProjectType = (projectType: ProjectTypeFilter) => (
  projects: Project[]
): Project[] => {
  switch (projectType) {
    case ProjectTypeFilter.archived: {
      return filter<Project>(isArchived)(projects)
    }
    case ProjectTypeFilter.active: {
      return filter<Project>(isActive)(projects)
    }
    case ProjectTypeFilter.all: {
      return projects
    }
    default: {
      return projects
    }
  }
}

export const filterCompaniesByLabels = (labels: string[]) => (companies: Company[]): Company[] => {
  return filter<Company>(company => {
    return allPass([hasCompanyLabels(labels)])(company)
  })(companies)
}
