import moment from 'moment'
import { DataFilter } from '../statsMoco/statFilter'
import { MockStatisticNames } from './types'
import { ProjectTypeFilter } from '../statsMoco/statFilter'
import { MockRootState } from '../../mockStore'

export const stubSelector = (state: MockRootState) => {
  return 'stubSelector'
}

export const getGeneral = (state: MockRootState) => {
  return state.general
}

export const isLoading = (state: MockRootState): boolean => {
  return getGeneral(state).pendingRequests > 0
}

export const getSyncError = (state: MockRootState): string => {
  return getGeneral(state).error
}

export const getCounter = (state: MockRootState): number => {
  return getGeneral(state).pendingRequests
}

export const getMaxCounter = (state: MockRootState): number => {
  return getGeneral(state).maxRequests
}

export const getFilter = (state: MockRootState, statistic: MockStatisticNames): DataFilter => {
  const general = getGeneral(state)
  const filter = general.statFilters[statistic]
  return {
    ...filter,
    projectType: filter.projectType ? filter.projectType : ProjectTypeFilter.all,
    startDate: filter.startDate ? moment(filter.startDate, 'YYYY-MM-DD') : null,
    endDate: filter.endDate ? moment(filter.endDate, 'YYYY-MM-DD') : null,
  }
}

export type AdditionalFilterInfo = {
  unit: 'h' | 'CHF / EUR'
}

export const getAdditionalInfo = (
  statistic: MockStatisticNames,
  state: MockRootState
): AdditionalFilterInfo => {
  const general = getGeneral(state)
  return {
    unit: general.statFilters[statistic].showStatsIn === 'hours' ? 'h' : 'CHF / EUR',
  }
}

export default {
  isLoading,
  getCounter,
  getMaxCounter,
}
