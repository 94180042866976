import React from 'react'
import { Company } from '../../state/modules/companies/types'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles'

//import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { HashLink as Link } from 'react-router-hash-link'

export enum CustomerStats {
  bbe = 'bbe',
  taskRevenue = 'taskRevenue',
  nothingSelected = 'nothingSelected',
}
type CustomerSelectionProps = {
  activeCustomerStat: number | CustomerStats
  customers: Company[]
  title: null | string
}

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    link: {
      textDecoration: 'none',
    },
  })

type StyleProps = WithStyles<typeof styles>

const CustomerSelection: React.StatelessComponent<CustomerSelectionProps & StyleProps> = ({
  activeCustomerStat,
  classes,
  customers,
  title,
}) => {
  const getAvatarName = (name: string) => {
    const parts = name.split(' ')
    let avatarName = ''
    if (parts[0]) {
      avatarName += parts[0][0]
    }
    if (parts[1]) {
      avatarName += parts[1][0]
    }
    return avatarName
  }

  return (
    <React.Fragment>
      {title && <Typography variant={'h4'}>{title}</Typography>}
      <List component="nav">
        {/*<Link className={classes.link} to={'/customer/bbe'}>
          <ListItem button key={'bbe'} disabled={activeCustomerStat === CustomerStats.bbe}>
            <ListItemIcon>
              <Avatar className={classes.avatar}>BBE</Avatar>
            </ListItemIcon>
            <ListItemText>Kunden-Bruttobetriebseinnahmen</ListItemText>
          </ListItem>
        </Link>

        {/*
        <Link className={classes.link} to={'/customer/taskRevenue'}>
          <ListItem button key={'taskRevenu'} disabled={activeCustomerStat === CustomerStats.taskRevenue}>
            <ListItemIcon>
              <Avatar className={classes.avatar}>E</Avatar>
            </ListItemIcon>
            <ListItemText>Kunden-Ertragsübersicht</ListItemText>
          </ListItem>
        </Link>
        */}
        <Divider />
        {customers.map((c, i) => {
          return (
            <>
              <Link className={classes.link} key={c.id} to={`/customer/${c.id}#pagetitle`}>
                <ListItem button disabled={c.id === activeCustomerStat}>
                  <ListItemIcon>
                    <Avatar className={classes.avatar}>{getAvatarName(c.name)}</Avatar>
                  </ListItemIcon>
                  <ListItemText inset primary={`${c.name}`} />
                </ListItem>
              </Link>
              <Divider />
            </>
          )
        })}
      </List>
    </React.Fragment>
  )
}

export default compose<CustomerSelectionProps & StyleProps, CustomerSelectionProps>(
  withStyles(styles)
)(CustomerSelection)
