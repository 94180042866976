import React, { useEffect } from 'react'
import ReactGA, { FieldsObject } from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'

const trackingId = 'UA-86559562-1' // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId)
ReactGA.set({
  anonymizeIp: true,

  // any data that is relevant to the user session
  // that you would like to track with google analytics
})

export function isDoNotTrackEnabled() {
  // use header if available, if not try the browser
  const doNotTrackOption =
    window.doNotTrack || window.navigator.doNotTrack || (window.navigator as any).msDoNotTrack

  if (!doNotTrackOption) {
    return false
  }

  if (doNotTrackOption.charAt(0) === '1' || doNotTrackOption === 'yes') {
    return true
  }

  return false
}

export const withTracker = <P extends RouteComponentProps<{}, {}>>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {}
) => {
  // Check if do not track me is activated
  const doNotTrackEnabled = isDoNotTrackEnabled()

  const trackPage = doNotTrackEnabled
    ? (page: string) => {}
    : (page: string) => {
        ReactGA.set({ page, ...options })
        ReactGA.pageview(page)
      }

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname)
    }, [props.location.pathname])

    return <WrappedComponent {...props} />
  }
}
