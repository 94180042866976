import { map } from 'ramda'
import { CustomProjectPropsResult, CustomProjectProps, ProjectCategory, Project } from './types'
// import { TypeKeys as types } from './constants';
import { TypeKeys as generalTypes } from '../general/constants'
import { ProjectState } from './types'
import { ProjectAction } from './actions'
import { mapToYearNumber } from '../users/reducers'

const initState = {
  detailsById: {},
}

const parseCustomProperties = (customProps: CustomProjectPropsResult): CustomProjectProps => {
  const Projektkategorie =
    (customProps.Projektkategorie as ProjectCategory) || ('Kundenprojekt' as ProjectCategory)

  const Bewertungskorrektur = mapToYearNumber(customProps.Bewertungskorrektur)
  const AngebotsReferenz = customProps.AngebotsReferenz || ''

  return {
    Projektkategorie,
    Bewertungskorrektur,
    AngebotsReferenz,
  }
}

const projects = (
  state: ProjectState = {
    detailsById: {},
    ...initState,
  },
  action: ProjectAction
): ProjectState => {
  switch (action.type) {
    case generalTypes.ADD_DATA: {
      if (action.payload.resource === 'projects') {
        // Map customProps to numbers
        const mapInternal = map((value: any) => {
          const customProps = parseCustomProperties(
            value.custom_properties as CustomProjectPropsResult
          )
          const mappedProject: Project = {
            ...value,
            custom_properties: {
              ...customProps,
            },
          }
          return mappedProject
        })(action.payload.data)

        return {
          ...state,
          detailsById: {
            ...state.detailsById,
            ...mapInternal,
          },
        }
      } else {
        return state
      }
    }

    case generalTypes.CLEAR_ALL: {
      return {
        ...state,
        detailsById: {},
      }
    }

    /* todo: implement again
    case types.REPLACE_ALL: {
      return { ...state, detailsById: action.payload, currentEvent: Object.keys(action.payload)[0] };
    }*/

    default:
      return state
  }
}

export default projects
