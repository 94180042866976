import { complement, filter, allPass, values, reduce, concat, sort, all, sortBy, prop } from 'ramda'
import moment from 'moment'
import { Moment } from 'moment'
import { RootState } from '../../store'
import { ProjectList, Project } from './types'
import { Invoice } from '../invoices/types'
import { Activity } from '../activities/types'
import { Company } from '../companies/types'
import { Expense } from '../expenses/types'
// import { CompanyList } from '../companies/types'
import { getInvoicesOfProject } from '../invoices/selectors'
import { getExpensesOfProject } from '../expenses/selectors'
import { getActivitiesOfProject } from '../activities/selectors'
import { ascendComparatorString } from '../../../util/ramdaUtil'
import { Offer } from '../offers/types'
import { getOfferByIdentifier } from '../offers/selectors'
import _, { uniq } from 'lodash'

export type ExtendedProject = Project & {
  company: Company
  expenses: Expense[]
  invoices: Invoice[]
  activities: Activity[]
  offers: Offer[]
}

export const getProjects = (state: RootState): ProjectList => {
  return state.projects.detailsById
}

export const getArrayProjects = (state: RootState): Project[] => {
  return _.values(getProjects(state))
}

export const getSortedArrayProjects = (state: RootState): Project[] => {
  const projects = getArrayProjects(state)
  return sortBy(prop('name'))(projects)
}

export const getProjectById = (state: RootState, id: number): Project => {
  return getProjects(state)[id]
}

/* Returns the correction of the year */
export const getCorrection = (year: string) => (project: Project): number => {
  if (project.custom_properties.Bewertungskorrektur) {
    const correction = project.custom_properties.Bewertungskorrektur[year]

    return correction ? correction : 0
  }
  return 0
}

export const isReducedProject = (project: Project) => {
  return project.labels.indexOf('Reduziert') > -1 || project.labels.indexOf('reduziert') > -1
}

export const isActive = (project: Project | ExtendedProject): boolean => {
  return project.active
}

export const isArchived = (project: Project | ExtendedProject): boolean => {
  return complement(isActive)(project)
}

const isAcquisition = (project: Project | ExtendedProject): boolean => {
  return !project.billable
}

const isNotAcquisition = (project: Project | ExtendedProject): boolean => {
  return complement(isAcquisition)(project)
}

export const getActiveProjects = (state: RootState): Project[] => {
  const projects = getProjects(state)
  return filter(allPass([isActive, isNotAcquisition]))(values(projects))
}

export const getActiveProjectsAcquisition = (state: RootState): Project[] => {
  const projects = getProjects(state)
  return filter(allPass([isAcquisition, isActive]))(values(projects))
}

export const getArchivedProjects = (state: RootState): Project[] => {
  const projects = getProjects(state)
  return filter(allPass([isArchived, isNotAcquisition]))(values(projects))
}

export const getArchivedAcquisitionProjects = (state: RootState): Project[] => {
  const projects = getProjects(state)
  return filter(allPass([isArchived, isAcquisition]))(values(projects))
}

export const mapToExtendedProjects = (
  projects: Project[],
  companies: Company[],
  activities: Activity[],
  expenses: Expense[],
  invoices: Invoice[],
  offers: Offer[]
): ExtendedProject[] => {
  const extendedProjects: ExtendedProject[] = projects.map((project: Project) => {
    const pExpenses = getExpensesOfProject(expenses, project.id)
    const pActivities = getActivitiesOfProject(activities, project.id)
    const pInvoice = getInvoicesOfProject(invoices, project.id)
    const pOffer = project.custom_properties.AngebotsReferenz
      ? getOfferByIdentifier(offers, project.custom_properties.AngebotsReferenz)
      : []
    const company = filter<Company>(c => c.id === project.customer.id)(companies)[0] // companies[project.customer.id]

    return {
      ...project,
      company,
      expenses: pExpenses,
      invoices: pInvoice,
      activities: pActivities,
      offers: pOffer,
    }
  })

  return extendedProjects
}

export const getProjectLabels = (state: RootState): string[] => {
  const projects = values(getProjects(state))

  const labels = reduce((labelResult: string[], project: Project) => {
    return concat(labelResult, project.labels)
  }, [])(projects)
  return sort(ascendComparatorString)(uniq(labels))
}

export const hasLabels = (labels: string[]) => (project: Project): boolean => {
  const hasLabel = (label: string): boolean => label === '' || project.labels.indexOf(label) > -1

  return all(hasLabel)(labels)
}

export const isInvoiceBetweenDates = (startDate: Moment, endDate: Moment) => (
  project: ExtendedProject
) => {
  const latestInvoiceDate = reduce((date: Moment, invoice: Invoice) => {
    const invoiceDate = moment(invoice.date)
    if (invoiceDate.isAfter(date)) {
      return invoiceDate
    }
    return date
  }, moment('2012-02-02'))(project.invoices)

  return latestInvoiceDate.isBetween(startDate, endDate, undefined, '[]')
}

/**
 * Returns the fixed percentage amount of fee(Honorar)
 * 5% < 20000, 4% 20k - 100k, 3% >= 100k
 * @param amount
 */
export const getSpesen = (amount: number): number => {
  let percentage = 5
  if (amount >= 20000) {
    percentage = 4
  }
  if (amount >= 100000) {
    percentage = 3
  }

  return (amount / 100) * percentage
}

export default {
  getProjects,
  getActiveProjects,
}
