import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import ErrorBoundary from 'react-error-boundary'
import './App.css'
import { Route, Switch, withRouter } from 'react-router-dom'
import EmployeeOverview from './page/employee/overview'
import EmployeePage from './page/employee/employee'
import CustomerOverview from './page/customer/overview'
import CustomerDetailsPage from './page/demo/CustomerDetailsPage'

/*import Overview from './page/overview'
import ProjectStats from './page/projectStats'
import EmployeeOverview from './page/employee/overview'
import EmployeePage from './page/employee/employee'
import AllEmployeesPage from './page/employee/allEmployees'
import LoginPage from './page/login'
import CustomerOverview from './page/customer/overview'
import CustomerBbe from './page/customer/bbe'
// import CustomerBbeBefore from './page/customer/bbeBefore';

import CustomerProfitability from './page/customer/profitablity'
import CustomerTaskRevenue from './page/customer/taskRevenues'
import SyncPage from './page/sync/SyncPage'
import ComaticCompanySincPage from './components/comatic/CompanySync'
import AdvertizerSyncPage from './page/sync/SyncPageAdvertizerTemp'*/
import OverviewStatsPage from './page/demo/companyOverview'

import showLoader from './page/showLoader'
import ErrorPage from './page/ErrorPage'
import FourOFourPage from './page/FourOFouPage'
// import { isUserAllowed } from './state/modules/users/selectors'
import { RootState } from './state/store'
import NoRightsPage from './page/NoRightsPage'
import ProjectOverview from './page/demo/ProjectOverview'
import ProjectDetailsPage from './page/demo/ProjectDetailsPage'
import DemoDataPage from './page/demo/DemoDataOverview'

type PublicProps = {}

type InjectedProps = {
  isAllowed: boolean
}

class App extends React.Component<InjectedProps> {
  public render() {
    const { isAllowed } = this.props
    if (!isAllowed) {
      return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Route path="/" component={NoRightsPage} />
        </ErrorBoundary>
      )
    }

    return (
      <div>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Switch>
            {/*
            <Route exact path="/" component={LoginPage} />
            <Route path="/overview" component={Overview} />
            <Route path="/projects" component={ProjectStats} />
            <Route exact path="/employee" component={EmployeeOverview} />
            <Route path="/employee/overview" component={AllEmployeesPage} />
            <Route path="/employee/:id" component={EmployeePage} />
            <Route exact path="/customer" component={CustomerOverview} />
            <Route exact path="/customer/bbe" component={CustomerBbe} />
            <Route exact path="/customer/taskRevenue" component={CustomerTaskRevenue} />
            <Route path="/customer/:id" component={CustomerProfitability} />
            <Route exact path="/comaticSync" component={SyncPage} />
            <Route path={'/comaticSync/companies'} component={ComaticCompanySincPage} />
            <Route path="/advertizerSync" component={AdvertizerSyncPage} />
             New version for mocoStats */}
            <Route exact path="/" component={OverviewStatsPage} />
            <Route exact path="/employee" component={EmployeeOverview} />
            <Route path="/employee/:id" component={EmployeePage} />

            <Route exact path="/customer" component={CustomerOverview} />
            <Route path="/customer/:id" component={CustomerDetailsPage} />

            <Route exact path="/project" component={ProjectOverview} />
            <Route path="/project/:id" component={ProjectDetailsPage} />

            <Route exact path="/demoData" component={DemoDataPage} />

            {/* These versions represent the dynamicaly evaluated bbe 
            <Route exact path="/customer/bbeBefore" component={CustomerBbeBefore} />
            */}

            {/* Catch all routes */}
            <Route component={FourOFourPage} />
          </Switch>
        </ErrorBoundary>
      </div>
    )
  }
}

const mapStateToProps = (state: RootState) => ({
  isAllowed: true, // isUserAllowed(state),
})

export default compose<InjectedProps, PublicProps>(
  withRouter,
  showLoader,
  connect(mapStateToProps)
)(App)
