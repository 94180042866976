import React from 'react'
import { FormattedNumber } from 'react-intl'

export function formatCurrency(num: number, withUnit = false) {
  return (
    <FormattedNumber
      value={num}
      style={withUnit ? 'currency' : 'decimal'}
      currency={'CHF'}
      minimumFractionDigits={2}
      maximumFractionDigits={2}
      currencyDisplay={'symbol'}
    />
  )
}

export function formatHour(num: number, withUnit = false) {
  return (
    <React.Fragment>
      <FormattedNumber value={num} style={`decimal`} maximumFractionDigits={2} />
      {withUnit ? 'h' : null}
    </React.Fragment>
  )
}

export function formatNumber(num: number) {
  return <FormattedNumber value={num} style={`decimal`} maximumFractionDigits={2} />
}

export function formatPercentage(num: number, withUnit = false) {
  return (
    <React.Fragment>
      <FormattedNumber value={num} style={`decimal`} maximumFractionDigits={2} />
      {withUnit ? '%' : null}
    </React.Fragment>
  )
}

/**
 * Check that num is not NaN or Infinite because of division by 0
 * If it is, replace with defaultValue
 */
export function convertPercentageNumber(num: number, defaultValue: number) {
  if (isFinite(num)) {
    if (isNaN(num)) {
      return defaultValue
    }
    return num * 100
  }

  return defaultValue
}
