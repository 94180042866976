interface ApiConfig {
  url: string
  originalUrl: string
  breakUrl: string // url if you need a break
  throttle: number // in ms
  fromDate: string
  resyncDays: number // Days to go back in time for resync of activities
}

const sharedConfig: Partial<ApiConfig> = {
  throttle: 1050,
  breakUrl: 'https://twteam.ch',
  resyncDays: 60,
}

const testConfig: Partial<ApiConfig> = {
  // url: 'https://bqniynw671.execute-api.eu-west-1.amazonaws.com/prod/',
  url: 'https://dczxuqssjc.execute-api.eu-west-1.amazonaws.com/testBeta/',
  originalUrl: 'https://twteam.ch',
  fromDate: '2017-01-01',
}

const productionConfig: Partial<ApiConfig> = {
  url: 'https://bqniynw671.execute-api.eu-west-1.amazonaws.com/prod/',
  originalUrl: 'https://twteam.ch',
  fromDate: '2018-09-01',
}

export const getConfig: () => ApiConfig = () => {
  if (process.env.REACT_APP_CONFIG !== 'production') {
    return {
      ...sharedConfig,
      ...testConfig,
    } as ApiConfig
  }
  return {
    ...sharedConfig,
    ...productionConfig,
  } as ApiConfig
}
