import React from 'react'
import { connect } from 'react-redux'
import DemoFilter from './DemoFilter'
import { MockStatisticNames } from '../../state/modules/mockGeneral/types'
import { OverviewStats, getOverviewStats } from '../../state/modules/statsMoco/overviewStats'
import { MockRootState } from '../../state/mockStore'
import { MyPieChart } from './components/MyPieChart'
import CardItem from './components/CardItem'
import ZoomIn from '@material-ui/icons/ZoomIn'
import CardContainer from './components/CardContainer'
import { diagramGridProps } from './constants'
//import { MyTreemap } from './components/MyTreemap'
import { MyLineChart } from './components/MyLineChart'
import Info from './components/Info'
import PeriodFilter from './PeriodFilter'
import { getAdditionalInfo, AdditionalFilterInfo } from '../../state/modules/mockGeneral/selectors'

type OverviewListProps = {
  data: OverviewStats
  info: AdditionalFilterInfo
}

const OverviewList: React.SFC<OverviewListProps> = ({ data, info }) => {
  const { byCustomer, byEmployee, byEmployeeUnit, byProject, byTask, byBillable } = data.proportions

  return (
    <React.Fragment>
      <DemoFilter statistic={MockStatisticNames.demo} />
      <CardContainer>
        <CardItem title="Kunden" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byCustomer} info={info} />
        </CardItem>

        <CardItem title="Personal" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byEmployee} info={info} />
        </CardItem>

        <CardItem title="Team" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byEmployeeUnit} info={info} />
        </CardItem>

        <CardItem title="Projekte" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byProject} info={info} />
        </CardItem>

        <CardItem
          title="Leistungsgruppen"
          icon={<ZoomIn />}
          gridProps={diagramGridProps}
          info={info}
        >
          <MyPieChart data={byTask} info={info} />
        </CardItem>

        <CardItem title="Verrechenbar" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byBillable} info={info} />
        </CardItem>
      </CardContainer>

      <CardContainer>
        <Info
          value={`${data.info.cntCompanies}`}
          range="Anzahl gefilterter Kunden"
          title="Anzahl Kunden"
        />
        <Info
          value={`${data.info.cntProjects}`}
          range="Anzahl gefilterter Projekte"
          title="Anzahl Projekte"
        />
        <Info
          value={`${data.info.cntDays}`}
          range="Tage mit erfassten Stunden"
          title="Arbeitstage"
        />
        <Info value={`${data.info.avgFee}`} range="Verrechbares Honorar" title="∅ Honorar" />
      </CardContainer>

      <PeriodFilter statistic={MockStatisticNames.demo} />

      <CardContainer>
        <CardItem title="Verlauf" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyLineChart data={data.progress.byProgressSum} info={info} />
        </CardItem>

        <CardItem title="Auslastung" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyLineChart data={data.progress.byPeriodSum} info={info} />
        </CardItem>
      </CardContainer>
    </React.Fragment>
  )
}

const mapStateToProps = (state: MockRootState): OverviewListProps => ({
  data: getOverviewStats(state),
  info: getAdditionalInfo(MockStatisticNames.demo, state),
})

export default connect(mapStateToProps)(OverviewList)
