import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { User } from '../../state/modules/users/types'
import { userSelector } from '../../state/modules/users/index'
import EmployeeDetails from '../../components/demo/EmployeeDetails'
// import EmployeeGoals from '../../components/employee/EmployeeGoals'
// import EmployeeTasks from '../../components/employee/EmployeeTasks'
import { authenticationSelector } from '../../state/modules/authentication'
import { withToggle, WithToggleProps } from '../../components/Sidebar/withToggle'
import EmployeeSelection from './EmployeeSelection'
import Typography from '@material-ui/core/Typography'
// import { getFilter } from '../../state/modules/general/selectors'
// import { StatisticNames } from '../../state/modules/general/types'
// import { DataFilter } from '../../state/modules/stats/statFilter'
// import DownIcon from '@material-ui/icons/KeyboardArrowDown'
// import LeftIcon from '@material-ui/icons/KeyboardArrowLeft'

type PublicEmployeeProps = {}

type EmployeeProps = {
  user: User
  users: User[]
  loggedIn: boolean
}

type ComponentProps = EmployeeProps & RouteComponentProps<{ id: number }> & WithToggleProps

const EmployeePage: React.SFC<ComponentProps> = ({ location, match, user, loggedIn, users }) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }
  return (
    <Layout routes={routes} location={location}>
      <EmployeeDetails user={user} />
      <Typography variant="h4" id="pagetitle">
        Weiteres Personal
      </Typography>
      <EmployeeSelection activeUserId={user.id} users={users} hideInfo />
    </Layout>
  )
}

const mapStateToProps = (
  state: RootState,
  props: RouteComponentProps<{ id: number }>
): EmployeeProps => ({
  user: userSelector.getUserById(state, props.match.params.id),
  loggedIn: authenticationSelector.isAuthenticated(state),
  users: userSelector.getUsersArray(state),
  // filter: getFilter(state, StatisticNames.employee),
})

export default compose<ComponentProps, PublicEmployeeProps>(
  connect(mapStateToProps),
  withToggle
)(EmployeePage)
