import { round } from 'lodash'
import { MockRootState } from '../../mockStore'
import { Activity } from '../activities/types'
import { compose, curry } from 'ramda'
import { Project } from '../projects/types'
import { Company } from '../companies/types'
import { User } from '../users/types'
import { PieStatsProp } from '../../../components/demo/components/MyPieChart'
import {
  stateToArray,
  filterByDataFilter,
  getProportionDiagramData,
  getDataFilter,
  getInitalValue,
  getProgressDiagramData,
  getWorkingDays,
  getTotal,
} from './helper'

export type OverviewStats = {
  allData: {
    activities: Activity[]
    companies: Company[]
    projects: Project[]
    users: User[]
  }
  filteredData: {
    activities: Activity[]
    companies: Company[]
    users: User[]
    projects: Project[]
  }
  proportions: {
    byProject: PieStatsProp[]
    byTask: PieStatsProp[]
    byEmployee: PieStatsProp[]
    byCustomer: PieStatsProp[]
    byBillable: PieStatsProp[]
    byEmployeeUnit: PieStatsProp[]
  }
  progress: {
    byPeriodSum: PieStatsProp[]
    byProgressSum: PieStatsProp[]
  }
  info: {
    cntCompanies: number
    cntProjects: number
    cntDays: number
    avgFee: number
  }
}

export const getOverviewStats = (state: MockRootState): OverviewStats => {
  // Get Filter
  const dataFilter = getDataFilter(state.general.statFilters.demo)

  const { activities, companies, projects, users } = stateToArray(state)

  const filteredData = compose(
    curry(filterByDataFilter)(dataFilter),
    stateToArray
  )(state)

  const avgFee = round(
    getTotal('amount', filteredData.activities) / getTotal('hours', filteredData.activities),
    2
  )

  const proportions = getProportionDiagramData(dataFilter.showStatsIn, filteredData)

  // Progress data
  const startValueAccumulated = getInitalValue(dataFilter, state)
  const progress = getProgressDiagramData(dataFilter, startValueAccumulated, filteredData)

  // MOCK DATA
  return {
    allData: {
      activities,
      companies,
      projects,
      users,
    },
    filteredData,
    proportions,
    progress,
    info: {
      cntCompanies: filteredData.companies.length,
      cntProjects: filteredData.projects.length,
      cntDays: getWorkingDays(filteredData.activities),
      avgFee: isFinite(avgFee) ? avgFee : 0,
    },
  }
}
