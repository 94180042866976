import React from 'react'
import {
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Line,
  XAxis,
  YAxis,
  LineChart,
  ReferenceLine,
} from 'recharts'
import { createCustomLinechartTooltip } from './CustomLinechartTooltip'
import { AdditionalFilterInfo } from '../../../state/modules/mockGeneral/selectors'
const placeholder = 'img/placeholder-nodata.png'

export type LineChartProps = {
  name: string
  value: number
}

type MyLineChartProps = {
  data: LineChartProps[]
  budget?: number
  info: AdditionalFilterInfo
}

export const MyLineChart: React.FC<MyLineChartProps> = props => {
  const { budget, data, info } = props
  const showPlaceholder = data && data.length === 0 ? true : false
  return showPlaceholder ? (
    <img alt="Platzhalter weil keine Daten" src={placeholder} />
  ) : (
    <ResponsiveContainer height={400} width="100%">
      <LineChart data={data}>
        <CartesianGrid />
        <Line type="monotone" dataKey="value" stroke="#19718C" fill="#19718C" />
        {budget && (
          <ReferenceLine
            y={budget}
            alwaysShow
            label={{ position: 'bottom', value: 'Budget', color: 'red' } as any}
            stroke="red"
            strokeDasharray="3 3"
          />
        )}
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={createCustomLinechartTooltip(info)} />
      </LineChart>
    </ResponsiveContainer>
  )
}
