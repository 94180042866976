import { groupBy, find, propEq, map, Dictionary } from 'ramda'
import {
  getActivitiesTotalHour,
  getActivitiesTotal,
  // getActivitiesTotalAll,
} from '../activities/selectors'
import { Activity } from '../activities/types'
import { Project } from '../projects/types'
import { Company } from '../companies/types'
import { User } from '../users/types'

// Anteile
type ProportionData = {
  name: string
  value: number
}

export const groupByProject = (activities: Activity[]) =>
  groupBy((activity: Activity) => activity.project.id)(activities)
export const groupByEmployee = (activities: Activity[]) =>
  groupBy((activity: Activity) => activity.user.id)(activities)
export const groupByEmployeeUnit = (users: User[]) => (activities: Activity[]) =>
  groupBy((activity: Activity) => {
    const user = find<User>(propEq('id', activity.user.id))(users)
    return user ? user.unit.name : 'undefined'
  })(activities)
export const groupByCustomer = (activities: Activity[]) =>
  groupBy((activity: Activity) => activity.customer.id)(activities)
export const groupByTask = (activities: Activity[]) =>
  groupBy((activity: Activity) => activity.task.name)(activities)
export const groupByBillable = (activities: Activity[]) =>
  groupBy((activity: Activity) => (activity.billable ? 'Verrechenbar' : 'Nicht verrechenbar'))(
    activities
  )

export const getProjectLabel = (
  projects: Project[],
  entries: ProportionData[]
): ProportionData[] => {
  return map((entry: ProportionData) => {
    const project = find<Project>(propEq('id', parseInt(entry.name)))(projects)
    return {
      ...entry,
      name: project ? project.name : entry.name,
    }
  })(entries)
}

export const getCustomerLabel = (
  customers: Company[],
  entries: ProportionData[]
): ProportionData[] => {
  return map((entry: ProportionData) => {
    const company = find<Company>(propEq('id', parseInt(entry.name)))(customers)
    return {
      ...entry,
      name: company ? company.name : entry.name,
    }
  })(entries)
}

export const getEmployeeLabel = (users: User[], entries: ProportionData[]): ProportionData[] => {
  return map((entry: ProportionData) => {
    const user = find<User>(propEq('id', parseInt(entry.name)))(users)
    return {
      ...entry,
      name: user ? `${user.firstname} ${user.lastname}` : entry.name,
    }
  })(entries)
}

export const getProportionData = (
  strategy: 'hours' | 'amount',
  activities: Dictionary<Activity[]>
) => {
  const diagramResult: ProportionData[] = []
  for (let [key, value] of Object.entries(activities)) {
    diagramResult.push({
      name: key,
      value: strategy === 'hours' ? getActivitiesTotalHour(value) : getActivitiesTotal(value),
    })
  }

  return diagramResult

  /* activities: [{id: number, project: {id: number}, hours: 3}, ]
  type ConvertType = {
    id: number
    value: number
  }
  const step = map<Activity, ConvertType>(activity => {
    return {
      id: activity.project.id,
      value: strategy === 'amount' ? activity.hours * activity.hourly_rate : activity.hours,
    }
  })(activities)

  groupBy(prop('value'))(step)

  return test

  const groupedActivities = groupBy(prop('project'))(activities)
  console.info(JSON.stringify(groupedActivities))

  const projectKeys = keys(groupedActivities)

  let byProject: ProportionData[] = []
  projectKeys.forEach((k, i) => {
    byProject.push({
      name: 'test' + i,
      value: sum(map(prop('hours'))(groupedActivities[k])),
    })
  })
  */
}
