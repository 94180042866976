import React from 'react'
import GridContainer from '../../dashboardPro/Grid/GridContainer'
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'

const styles = createStyles({
  card: {},
})

type CardContainerProps = {}

type CardContainerInjectedProps = WithStyles<typeof styles>

const CardContainer: React.FC<CardContainerProps & CardContainerInjectedProps> = ({
  classes,
  children,
}) => {
  return <GridContainer className={classes.card}>{children}</GridContainer>
}

export default compose<CardContainerProps & CardContainerInjectedProps, CardContainerProps>(
  withStyles(styles)
)(CardContainer)
