import { sortBy, values } from 'lodash'
import { map, filter } from 'ramda'
import moment from 'moment'
import { RootState } from '../../store'
import { UserList, User, UserGoal, YearNumber } from './types'
import { DataFilter } from '../stats/statFilter'
import { getAuthenticatedUser } from '../authentication/selector'

export const getUsers = (state: RootState): UserList => {
  return state.users.detailsById
}

export const getUsersArray = (state: RootState): User[] => {
  return sortBy(values(getUsers(state)), ['lastname', 'firstname'])
}

export const getUserById = (state: RootState, id: number) => {
  return getUsers(state)[id]
}

export const getFilterYear = (filter: DataFilter) => {
  if (filter.endDate) {
    return filter.endDate.get('year').toString(10)
  }
  return moment()
    .get('year')
    .toString(10)
}

export const getGoals = (year: string, user: User): UserGoal => {
  const { Austrittsdatum, Eintrittsdatum, isAllowed, ...restYearNumbers } = user.custom_properties

  const goals: UserGoal = map<YearNumber, number>(property => {
    if (property && property[year]) {
      return property[year]
    }
    return 0
  })(restYearNumbers)
  return goals
}

export const getInactiveUsers = (state: RootState) => {
  return filter<User>(user => !user.active)(getUsersArray(state))
}

export const getInternalUsers = (state: RootState) => {
  return filter<User>(user => (user.unit ? user.unit.name !== 'Externe' : false))(
    getUsersArray(state)
  )
}

export const getActiveInternalUsers = (state: RootState) => {
  return filter<User>(user =>
    user.unit ? user.unit.name !== 'Externe' && user.active : user.active
  )(getUsersArray(state))
}

export const getExterneUsers = (state: RootState) => {
  return filter<User>(user => (user.unit ? user.unit.name === 'Externe' : true))(
    getUsersArray(state)
  )
}

export const isUserAllowed = (state: RootState) => {
  const loggedInUserId = getAuthenticatedUser(state)
  if (loggedInUserId === 0) {
    // User is allowed to login, if he's not logged in yet
    return true
  }

  const user = getUserById(state, loggedInUserId)

  if (user) {
    return user.custom_properties.isAllowed
  }

  return false
}

export default {
  getUsers,
  getUsersArray,
  getActiveInternalUsers,
  getInactiveUsers,
  getInternalUsers,
  getExterneUsers,
  getUserById,
}
