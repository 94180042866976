import { round } from 'lodash'
import {
  values,
  compose,
  curry,
  pipe,
  keys,
  groupBy,
  map,
  zipObj,
  toPairs,
  Dictionary,
  mean,
  prop,
  median,
  sort,
  tail,
  ascend,
} from 'ramda'
import { MockRootState } from '../../mockStore'
import { Activity } from '../activities/types'
import { User } from '../users/types'
import { Project } from '../projects/types'
import { Company } from '../companies/types'
import {
  DataFilter,
  filterCompaniesByLabels,
  filterProjectsByCompanies,
  filterProjectsByLabels,
  filterProjectsByProjectType,
  filterActivitiesByEmployee,
  filterActivitiesByCustomers,
  filterActivitiesByProjects,
  StatsDuration,
  StatsStrategy,
} from './statFilter'
import {
  getActivitiesBetweenDates,
  getActivitiesTotalHour,
  getActivitiesTotal,
} from '../activities/selectors'
import moment from 'moment'
import {
  getProportionData,
  getProjectLabel,
  groupByProject,
  getEmployeeLabel,
  groupByEmployee,
  getCustomerLabel,
  groupByCustomer,
  groupByTask,
  groupByBillable,
  groupByEmployeeUnit,
} from './statDiagram'
import { MockStatFilter } from '../mockGeneral/types'
import { PieStatsProp } from '../../../components/demo/components/MyPieChart'

export function objectToArray<T extends object>(state: { detailsById: { [key: number]: T } }): T[] {
  return values(state.detailsById)
}

type MockArrayState = {
  activities: Activity[]
  companies: Company[]
  projects: Project[]
  users: User[]
}

export function stateToArray(state: MockRootState): MockArrayState {
  return {
    activities: objectToArray(state.activities),
    companies: objectToArray(state.companies),
    projects: objectToArray(state.projects),
    users: objectToArray(state.users),
  }
}

export function getDataFilter(filter: MockStatFilter): DataFilter {
  const startDate = filter.startDate ? moment(filter.startDate) : moment('2000-01-01')
  const endDate = filter.endDate ? moment(filter.endDate) : moment('2099-12-31')

  const dataFilter: DataFilter = {
    ...filter, //.general.statFilters.demo,
    startDate,
    endDate,
  }

  return dataFilter
}

export function filterByDataFilter(filter: DataFilter, data: MockArrayState): MockArrayState {
  const { activities, companies, projects, users } = data
  const startDate = filter.startDate ? moment(filter.startDate) : moment('2000-01-01')
  const endDate = filter.endDate ? moment(filter.endDate) : moment('2099-12-31')

  const filteredCompanies = filterCompaniesByLabels(filter.customerLabels)(companies)

  // Filter data with Filter
  const filteredProjects = compose(
    filterProjectsByCompanies(filteredCompanies),
    filterProjectsByLabels(filter.labels),
    filterProjectsByProjectType(filter.projectType)
  )(projects)

  const filteredUsers = users

  const filteredActivities = compose(
    curry(getActivitiesBetweenDates)(startDate)(endDate),
    curry(filterActivitiesByEmployee)(filteredUsers),
    curry(filterActivitiesByCustomers)(filteredCompanies),
    curry(filterActivitiesByProjects)(filteredProjects)
  )(activities)

  return {
    activities: filteredActivities,
    companies: filteredCompanies,
    projects: filteredProjects,
    users: filteredUsers,
  }
}

export function getProportionDiagramData(showStatsIn: 'hours' | 'amount', data: MockArrayState) {
  const { activities, companies, projects, users } = data

  // Get Data for Diagram
  const sumProportionData = curry(getProportionData)(showStatsIn)

  // By Project
  const byProject = compose(
    curry(sortByProp)('name'),
    curry(roundValue)(2),
    curry(getProjectLabel)(projects),
    sumProportionData,
    groupByProject
  )(activities)

  const byEmployee = compose(
    curry(sortByProp)('name'),
    curry(roundValue)(2),
    curry(getEmployeeLabel)(users),
    sumProportionData,
    groupByEmployee
  )(activities)

  const byCustomer = compose(
    curry(sortByProp)('name'),
    curry(roundValue)(2),
    curry(getCustomerLabel)(companies),
    sumProportionData,
    groupByCustomer
  )(activities)

  const byTask = compose(
    curry(sortByProp)('name'),
    curry(roundValue)(2),
    // Label is already included
    sumProportionData,
    groupByTask
  )(activities)

  const byBillable = compose(
    curry(roundValue)(2),
    // Label is already included
    sumProportionData,
    groupByBillable
  )(activities)

  const byEmployeeUnit = compose(
    curry(roundValue)(2),
    // Label is already included
    sumProportionData,
    groupByEmployeeUnit(users)
  )(activities)

  return {
    byProject,
    byEmployee,
    byCustomer,
    byTask,
    byBillable,
    byEmployeeUnit,
  }
}

/******************************************
 * Progress Diagram related
 *****************************************/

export function getProgressDiagramData(
  dataFilter: DataFilter,
  startValueAccumulated: PieStatsProp | null,
  filteredData: MockArrayState
) {
  const progressSum = pipe(
    curry(groupByDuration)(dataFilter.duration),
    curry(groupTotals)(dataFilter.showStatsIn),
    curry(sortByDate)(dataFilter.duration),
    curry(getAccumulatedStats)(startValueAccumulated),
    curry(roundValue)(2)
  )(filteredData.activities)

  const totalsPerPeriod = pipe(
    curry(groupByDuration)(dataFilter.duration),
    curry(groupTotals)(dataFilter.showStatsIn),
    curry(sortByDate)(dataFilter.duration),
    curry(roundValue)(2)
  )(filteredData.activities)

  /*
  const progressMedian = pipe(
    curry(groupByDuration)(dataFilter.duration),
    curry(groupTotals)(dataFilter.showStatsIn),
    curry(sortByDate)(dataFilter.duration),
    curry(roundValue)(2)
  )(filteredData.activities)
  */

  return {
    byPeriodSum: totalsPerPeriod,
    byProgressSum: progressSum,
    // byMedian: progressMedian,
  }
}

export function roundValue(decimals: number, stats: PieStatsProp[]) {
  return map<PieStatsProp, PieStatsProp>(stat => {
    return {
      ...stat,
      value: round(stat.value, decimals),
    }
  })(stats)
}

export function getWorkingDays(filteredActivities: Activity[]) {
  const workingDays = pipe(
    curry(groupByDuration)('day'),
    keys
  )(filteredActivities)
  return workingDays.length
}

export function getDurationFormat(duration: StatsDuration): string {
  let dateFormat = 'YYYY'
  switch (duration) {
    case 'quarter':
      dateFormat = 'YYYY-Q'
      break
    case 'month':
      dateFormat = 'YYYY-MM'
      break
    case 'week':
      dateFormat = 'YYYY-WW'
      break
    case 'day':
      dateFormat = 'YYYY-MM-DD'
  }
  return dateFormat
}

export function groupByDuration(duration: StatsDuration, activities: Activity[]) {
  const dateFormat = getDurationFormat(duration)
  return groupBy<Activity>(a => {
    const date = moment(a.date)
    return date.format(dateFormat)
  })(activities)
}

function convertToStats(data: Record<string, number>): PieStatsProp[] {
  // convert toStats [{ name :: String, value :: a }]
  const convert = compose(
    map(zipObj(['name', 'value'])),
    toPairs
  ) // can'0t type it correctly because of unknown type

  return convert(data) as any
}

export const getTotal = (showStatsIn: StatsStrategy, activities: Activity[]) => {
  return showStatsIn === 'hours'
    ? getActivitiesTotalHour(activities)
    : getActivitiesTotal(activities)
}

export function groupTotals(showStatsIn: StatsStrategy, activityDict: Dictionary<Activity[]>) {
  const totals = map(curry(getTotal)(showStatsIn))(activityDict)
  return convertToStats(totals)
}

export function groupAvg(showStatsIn: StatsStrategy, activityDict: Dictionary<Activity[]>) {
  // const average = converge(divide, [sum, length])
  const getAvg = (activities: Activity[]) => {
    return showStatsIn === 'hours'
      ? mean(map<Activity, number>(prop('hours'))(activities))
      : mean(
          map<Activity, number>(a => {
            return a.billable ? a.hourly_rate * a.hours : 0
          })(activities)
        )
  }
  return convertToStats(map(getAvg)(activityDict))
}

export function groupMedian(showStatsIn: StatsStrategy, activityDict: Dictionary<Activity[]>) {
  const getMedian = (activities: Activity[]) => {
    return showStatsIn === 'hours'
      ? median(map<Activity, number>(prop('hours'))(activities))
      : median(
          map<Activity, number>(a => {
            return a.billable ? a.hourly_rate * a.hours : 0
          })(activities)
        )
  }
  return convertToStats(map(getMedian)(activityDict))
}

// export function groupAvg(showStatsIn: StatsStrategy, activityNum: )

export function sortByDate(duration: StatsDuration, stats: PieStatsProp[]) {
  const dateFormat = getDurationFormat(duration)

  const sortedStats = sort((a: PieStatsProp, b: PieStatsProp) => {
    return moment(a.name, dateFormat).isBefore(moment(b.name, dateFormat)) ? -1 : 0
  })(stats)

  return sortedStats
}

export function sortByProp(propName: keyof PieStatsProp, stats: PieStatsProp[]): PieStatsProp[] {
  const byName = ascend(prop(propName))

  return sort<PieStatsProp>(byName, stats)
}

/*
export function addZerosToDurations(duration: StatsDuration, stats: PieStatsProp[]){
  const format = getDurationFormat(duration);
  stats.reduce(
    (accu, stat) => {
      moment(stat.name, format).
    }, []
  )
}
*/

/*
export function getProgressDiagramData(
  showStatsIn: 'hours' | 'amount',
  duration: StatsDuration,
  strategy: 'avg' | 'sum' | 'median',
  activities: Activity[]
) {
  // Group by duration
  let dateFormat = 'YYYY'
  switch (duration) {
    case 'quarter':
      dateFormat = 'YYYY-Q'
      break
    case 'month':
      dateFormat = 'YYYY-MM'
      break
    case 'week':
      dateFormat = 'YYYY-WW'
      break
    case 'day':
      dateFormat = 'YYYY-MM-DD'
  }
  const progressBy = groupBy<Activity>(a => {
    const date = moment(a.date)
    return date.format(dateFormat)
  })(activities)

  // total of activities per duration time
  const getTotal = (activities: Activity[]) => {
    return showStatsIn === 'hours'
      ? getActivitiesTotalHour(activities)
      : getActivitiesTotal(activities)
  }
  const average = converge(divide, [sum, length])
  const getAvg = (activities: Activity[]) => {
    return showStatsIn === 'hours'
      ? average(map<Activity, number>(prop('hours'))(activities))
      : average(
          map<Activity, number>(a => {
            return a.billable ? a.hourly_rate * a.hours : 0
          })(activities)
        )
  }

  const durationTotals = map(strategy === 'avg' ? getAvg : getTotal)(progressBy)

  // convert toStats [{ name :: String, value :: a }]
  const convertToStats = compose(
    map(zipObj(['name', 'value'])),
    toPairs
  )

  // sort by date defined by duration format
  const sortedStats = sort((a: PieStatsProp, b: PieStatsProp) => {
    return moment(a.name, dateFormat).isBefore(moment(b.name)) ? -1 : 0
  })(convertToStats(durationTotals) as any)

  // Accumulate for sum
  return strategy === 'sum' ? getAccumulatedStats(null, sortedStats) : sortedStats
}
*/

export function getAccumulatedStats(start: PieStatsProp | null, data: PieStatsProp[]) {
  const initial = start ? [start] : []
  const result = data.reduce((accu, current) => {
    const resultBefore = accu.length === 0 ? 0 : accu[accu.length - 1].value
    return [
      ...accu,
      {
        ...current,
        value: current.value + resultBefore,
      },
    ]
  }, initial)
  // If start is set, remove temporary start entry
  if (start) {
    return tail(result)
  }

  return result
}

/*
export function getProgressDataWithStartValue(
  filteredDiagram: PieStatsProp[],
  unfilteredDiagram: PieStatsProp[]
) {
  if (unfilteredDiagram.length < filteredDiagram.length) {
    throw new Error('Change params to match filteredActivities, unfilteredActivities')
  }

  const test = head(filteredDiagram)
  if (test) {
    const startValue = find<PieStatsProp>(propEq('name', test.name))(unfilteredDiagram)

    const start: PieStatsProp[] = startValue ? [startValue] : []

    const result = getAccumulatedStats(start[0], filteredDiagram)
    return result
  }
  return filteredDiagram
}
*/

export function getInitalValue(dataFilter: DataFilter, state: MockRootState) {
  // Get Initial
  let initValue = null
  if (dataFilter.startDate) {
    const { activities: activitiesBeforeRange } = compose(
      curry(filterByDataFilter)({
        ...dataFilter,
        startDate: null,
        endDate: dataFilter.startDate,
      }),
      stateToArray
    )(state)

    const format = getDurationFormat(dataFilter.duration)
    initValue = {
      name: moment(dataFilter.startDate).format(format), // getDurationFormat(dataFilter.start
      value: curry(getTotal)(dataFilter.showStatsIn)(activitiesBeforeRange),
    }
  }
  return initValue
}
