/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
// @material-ui/icons
// core components
import cardIconStyle from '../assets/jss/material-dashboard-pro-react/components/cardIconStyle'
import { compose } from 'recompose'

type CardIconProps = {
  className: string
  color?: 'warning' | 'success' | 'danger' | 'info' | 'primary' | 'rose'
}

type CardIconInjectedProps = WithStyles<typeof cardIconStyle>

const CardIcon: React.FC<CardIconProps & CardIconInjectedProps> = ({ ...props }) => {
  const { classes, className, children, color, ...rest } = props
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color + 'CardHeader']]: color,
    [className]: className !== undefined,
  })
  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  )
}
export default compose<CardIconInjectedProps & CardIconProps, Partial<CardIconProps>>(
  withStyles(cardIconStyle)
)(CardIcon)
