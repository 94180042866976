import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { authenticationSelector } from '../../state/modules/authentication'
import Typography from '@material-ui/core/Typography'
import { getProjectById, getSortedArrayProjects } from '../../state/modules/projects/selectors'
import ProjectDetails from '../../components/demo/ProjectDetails'
import ProjectSelection from './ProjectSelection'
import { Project } from '../../state/modules/projects/types'

type ProjectDetailsProps = {
  project: Project
  projects: Project[]
  loggedIn: boolean
}

type ComponentProps = ProjectDetailsProps & RouteComponentProps<{ id: string }>

/**
 * Page for Brutto-Betriebseinnahmen
 */
const ProjectDetailsPage: React.SFC<ComponentProps> = ({
  projects,
  location,
  match,
  project,
  loggedIn,
}) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'h5'} id="pagetitle">
        Projekt {project.name}
      </Typography>
      <ProjectDetails project={project} />
      <ProjectSelection activeProjectId={project.id} projects={projects} title="Weitere Projekte" />
    </Layout>
  )
}

const mapStateToProps = (
  state: RootState,
  prop: RouteComponentProps<{ id: string }>
): ProjectDetailsProps => ({
  project: getProjectById(state, parseInt(prop.match.params.id, 10)),
  projects: getSortedArrayProjects(state), // .getCustomers(state, true),
  loggedIn: authenticationSelector.isAuthenticated(state),
})

export default compose(connect(mapStateToProps))(ProjectDetailsPage)
