// ##############################
// // // Sidebar styles
// #############################'
import { Theme, createStyles } from '@material-ui/core/styles'
import {
  boxShadow,
  dangerColor,
  defaultFont,
  drawerWidth,
  primaryColor,
  primaryBoxShadow,
  successColor,
  transition,
  warningColor,
} from '../../theme/variables'

export type Color = 'purple' | 'blue' | 'green' | 'orange' | 'red'

export const sidebarStyle = (theme: Theme) =>
  createStyles({
    drawerPaper: {
      border: 'none',
      position: 'fixed',
      top: '0',
      bottom: '0',
      left: '0',
      zIndex: 1,
      // overflow: 'auto',
      ...boxShadow,
      width: drawerWidth,
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        position: 'fixed',
        height: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: drawerWidth,
        ...boxShadow,
        position: 'fixed',
        display: 'block',
        top: '0',
        height: '100vh',
        right: '0',
        left: 'auto',
        zIndex: '1032',
        visibility: 'visible',
        overflowY: 'visible',
        borderTop: 'none',
        textAlign: 'left',
        paddingRight: '0px',
        paddingLeft: '0',
        transform: `translate3d(${drawerWidth}px, 0, 0)`,
        ...transition,
      },
    },
    logo: {
      position: 'relative',
      padding: '15px 15px',
      zIndex: 4,
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: '0',

        height: '1px',
        right: '15px',
        width: 'calc(100% - 30px)',
        backgroundColor: 'rgba(180, 180, 180, 0.3)',
      },
    },
    logoLink: {
      ...defaultFont,
      textTransform: 'uppercase',
      padding: '5px 0',
      display: 'block',
      fontSize: '18px',
      textAlign: 'left',
      fontWeight: 400,
      lineHeight: '30px',
      textDecoration: 'none',
      backgroundColor: 'transparent',
      '&,&:hover': {
        color: '#FFFFFF',
      },
    },
    logoImage: {
      width: '30px',
      display: 'inline-block',
      maxHeight: '30px',
      marginLeft: '10px',
      marginRight: '15px',
    },
    img: {
      width: '35px',
      top: '22px',
      position: 'absolute',
      verticalAlign: 'middle',
      border: '0',
    },
    background: {
      position: 'absolute' as 'absolute',
      zIndex: 1,
      height: '100%',
      width: '100%',
      display: 'block',
      top: '0',
      left: '0',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      '&:after': {
        position: 'absolute' as 'absolute',
        zIndex: 3,
        width: '100%',
        height: '100%',
        content: '""',
        display: 'block',
        background: '#000',
        opacity: 0.8,
      },
    },
    list: {
      marginTop: '20px',
      paddingLeft: '0',
      paddingTop: '0',
      paddingBottom: '0',
      marginBottom: '0',
      listStyle: 'none',
    },
    item: {
      position: 'relative',
      display: 'block',
      textDecoration: 'none',
    },
    itemLink: {
      width: 'auto',
      transition: 'all 300ms linear',
      margin: '10px 15px 0',
      borderRadius: '3px',
      position: 'relative',
      display: 'block',
      padding: '10px 15px',
      backgroundColor: 'transparent',
      ...defaultFont,
    },
    itemLinkCTA: {
      background: '#ff6666',
      opacity: 0.7,
      '&:hover': {
        opacity: 0.9,
        background: '#ff6666',
      },
    },
    itemIcon: {
      width: '24px',
      height: '30px',
      float: 'left',
      marginRight: '15px',
      textAlign: 'center',
      verticalAlign: 'middle',
      color: 'rgba(255, 255, 255, 0.8)',
    },
    itemIconCTA: {
      color: '#fff',
    },
    itemText: {
      ...defaultFont,
      margin: '0',
      lineHeight: '30px',
      fontSize: '14px',
      color: '#FFFFFF',
    },
    whiteFont: {
      color: '#FFFFFF',
    },
    purple: {
      backgroundColor: primaryColor,
      ...primaryBoxShadow,
      '&:hover': {
        backgroundColor: primaryColor,
        ...primaryBoxShadow,
      },
    },
    blue: {
      backgroundColor: primaryColor,
      boxShadow:
        '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
      '&:hover': {
        backgroundColor: primaryColor,
        boxShadow:
          '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
      },
    },
    green: {
      backgroundColor: successColor,
      boxShadow:
        '0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)',
      '&:hover': {
        backgroundColor: successColor,
        boxShadow:
          '0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)',
      },
    },
    orange: {
      backgroundColor: warningColor,
      boxShadow:
        '0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)',
      '&:hover': {
        backgroundColor: warningColor,
        boxShadow:
          '0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)',
      },
    },
    red: {
      backgroundColor: dangerColor,
      boxShadow:
        '0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)',
      '&:hover': {
        backgroundColor: dangerColor,
        boxShadow:
          '0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)',
      },
    },
    sidebarWrapper: {
      position: 'relative',
      height: 'calc(100vh - 75px)',
      overflow: 'auto',
      width: '260px',
      zIndex: 4,
      overflowScrolling: 'touch',
    },
  })
