import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { getEmployeeStats } from '../../state/modules/statsMoco/employeeStats'
// import { getData, EmployeeOverview } from '../../state/modules/stats/employeeSelectors'
import { User } from '../../state/modules/users/types'
import Typography from '@material-ui/core/Typography'
import { MockRootState } from '../../state/mockStore'
import { OverviewStats } from '../../state/modules/statsMoco/overviewStats'
import { MockStatisticNames } from '../../state/modules/mockGeneral/types'
import { MyPieChart } from './components/MyPieChart'
import DemoFilter from './DemoFilter'
import { diagramGridProps } from './constants'
import CardContainer from './components/CardContainer'
import CardItem from './components/CardItem'
import ZoomIn from '@material-ui/icons/ZoomIn'
//import { MyTreemap } from './components/MyTreemap'
import { MyLineChart } from './components/MyLineChart'
import Info from './components/Info'
import PeriodFilter from './PeriodFilter'
import { getAdditionalInfo, AdditionalFilterInfo } from '../../state/modules/mockGeneral/selectors'

type EmployeeStatsPropsPublic = {
  user: User
}

interface EmployeeStatsProps {
  data: OverviewStats
  user: User
  info: AdditionalFilterInfo
}

const EmployeeStats: React.SFC<EmployeeStatsProps> = ({ data, user, info }) => {
  const {
    proportions: { byBillable, byCustomer, byProject, byTask },
  } = data

  return (
    <React.Fragment>
      <Typography variant={'h5'} id="pagetitle">
        {user.firstname} {user.lastname}
      </Typography>
      <DemoFilter statistic={MockStatisticNames.demo} hideEmployee />

      <CardContainer>
        <CardItem title="Kunden" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byCustomer} info={info} />
        </CardItem>

        {/* Unsuitable
        <CardItem title="Personal" icon={<ZoomIn />} gridProps={diagramGridProps}>
          <MyPieChart data={byEmployee} />
        </CardItem>

        <CardItem title="Team" icon={<ZoomIn />} gridProps={diagramGridProps}>
          <MyPieChart data={byEmployeeUnit} />
        </CardItem>
        */}
        <CardItem title="Projekte" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byProject} info={info} />
        </CardItem>

        <CardItem
          title="Leistungsgruppen"
          icon={<ZoomIn />}
          gridProps={diagramGridProps}
          info={info}
        >
          <MyPieChart data={byTask} info={info} />
        </CardItem>

        <CardItem title="Verrechenbar" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byBillable} info={info} />
        </CardItem>
      </CardContainer>

      <CardContainer>
        <Info
          value={`${data.info.cntCompanies}`}
          range="Anzahl gefilterter Kunden"
          title="Anzahl Kunden"
        />
        <Info
          value={`${data.info.cntProjects}`}
          range="Anzahl gefilterter Projekte"
          title="Anzahl Projekte"
        />
        <Info
          value={`${data.info.cntDays}`}
          range="Anzahl Tage mit Stunden für Auswertung"
          title="Anzahl Arbeitstage"
        />
        <Info value={`${data.info.avgFee}`} range="Verrechbares Honorar" title="∅ Honorar" />
      </CardContainer>

      <PeriodFilter statistic={MockStatisticNames.demo} />

      <CardContainer>
        <CardItem title="Verlauf" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyLineChart data={data.progress.byProgressSum} info={info} />
        </CardItem>

        <CardItem title="Auslastung" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyLineChart data={data.progress.byPeriodSum} info={info} />
        </CardItem>
      </CardContainer>
    </React.Fragment>
  )
}

const mapStateToProps = (
  state: MockRootState,
  props: EmployeeStatsPropsPublic
): EmployeeStatsProps => ({
  data: getEmployeeStats(state, props.user),
  user: props.user,
  info: getAdditionalInfo(MockStatisticNames.demo, state),
})

export default compose<EmployeeStatsProps, EmployeeStatsPropsPublic>(
  connect<EmployeeStatsProps>(mapStateToProps)
)(EmployeeStats)
