import { filter as filterL } from 'lodash'
import moment from 'moment'
import { prop, reduce, filter, concat } from 'ramda'
import { Moment } from 'moment'
import { RootState } from '../../store'
import { Activity, ActivityList } from './types'
import { ExtendedProject, isActive, isArchived, isReducedProject } from '../projects/selectors'
import { ProjectList } from '../projects/types'
import { convertDate } from '../../../util/date'

export const getActivities = (state: RootState): ActivityList => {
  return state.activities.detailsById
}

export const getBillableActivities = (state: RootState): Activity[] => {
  return filterL(getActivities(state), activity => activity.billable)
}

export const getBillableActivitiesByProjectId = (
  state: RootState,
  projectId: number
): Activity[] => {
  return filterL(getBillableActivities(state), activity => activity.project.id === projectId)
}

export const isBillableActivity = (activity: Activity) => prop('billable')(activity)

export const getActivitiesOfProject = (activities: Activity[], projectId: number): Activity[] => {
  return activities.filter((activity: Activity) => activity.project.id === projectId)
}

export const getActivitiesOfUser = (activities: Activity[], userId: number): Activity[] => {
  return activities.filter((activity: Activity) => {
    return activity.user.id === userId
  })
}

export const getActivitiesTotalHour = (activities: Activity[]): number => {
  return activities.reduce((hours: number, activity: Activity) => {
    // if (activity.billable) {
    return hours + activity.hours
    // }
    // hours
  }, 0)
}

export const getActivitiesTotalAll = (activities: Activity[]): number => {
  return activities.reduce((amount: number, activity: Activity) => {
    return amount + activity.hourly_rate * activity.hours
  }, 0)
}

export const getActivitiesTotal = (activities: Activity[]): number => {
  return activities.reduce((amount: number, activity: Activity) => {
    if (activity.billable) {
      return amount + activity.hourly_rate * activity.hours
    }
    return amount
  }, 0)
}

export const getActivitiesOfExtendedProjectList: (
  projects: ExtendedProject[]
) => Activity[] = reduce((activities: Activity[], project: ExtendedProject) => {
  return [...activities, ...project.activities]
}, [])

export const getActivitiesBetweenDates = (
  startDate: Moment,
  endDate: Moment,
  activities: Activity[]
): Activity[] => {
  return filter((activity: Activity) => {
    const activityDate = moment(activity.date)
    return activityDate.isBetween(startDate, endDate, 'day', '[]')
  })(activities)
}

export const getLatestActivity = (activities: Activity[]): Activity | undefined => {
  const initActiivity = activities[0]
  if (initActiivity) {
    const latestActivity = reduce((maxActivity: Activity, activity: Activity) => {
      const currentLatestDate = moment(maxActivity.date)
      const newLatestDate = moment(activity.date)
      if (newLatestDate.isAfter(currentLatestDate)) {
        return activity
      }
      return maxActivity
    }, initActiivity)(activities)
    return latestActivity
  }
  // No activities found
  return undefined
}

export const getFeeCurrent = (startDate: Moment, endDate: Moment) =>
  filter(
    (a: Activity) =>
      convertDate(a.date).isSameOrAfter(startDate) && convertDate(a.date).isSameOrBefore(endDate)
  )

export const getFeeDifferentPeriod = (startDate: Moment, endDate: Moment) =>
  filter(
    (a: Activity) => convertDate(a.date).isBefore(startDate) || convertDate(a.date).isAfter(endDate)
  )

export type BilledActivities = {
  billedNormalActivities: Activity[]
  billedReducedActivities: Activity[]
  otherActivities: Activity[]
}

/**
 * Billed activities
 * @param activities
 * @param projects
 */
export const getBilledActivities = (
  activities: Activity[],
  projects: ProjectList
): BilledActivities => {
  const emptyBilledResult: BilledActivities = {
    billedNormalActivities: [],
    billedReducedActivities: [],
    otherActivities: [],
  }

  return reduce((result: BilledActivities, activity: Activity) => {
    const project = projects[activity.project.id]

    const archived = isArchived(project)
    const active = isActive(project)

    if (active) {
      return {
        ...result,
        otherActivities: concat(result.otherActivities, [activity]),
      }
    } else if (archived) {
      if (isReducedProject(project)) {
        return {
          ...result,
          billedReducedActivities: concat(result.billedReducedActivities, [activity]),
        }
      } else {
        return {
          ...result,
          billedNormalActivities: concat(result.billedNormalActivities, [activity]),
        }
      }
    }

    return result
  }, emptyBilledResult)(activities)
}

export type CategorizedActivities = {
  normalActivities: Activity[]
  reducedActivities: Activity[]
  nonBillableNormal: Activity[]
  nonBillableReduced: Activity[]
  newBusinessActivities: Activity[]
  customerRelationActivities: Activity[]
  marketingActivities: Activity[]
  internalActivities: Activity[]
}

const addCategory = (
  category: keyof CategorizedActivities,
  result: CategorizedActivities,
  activity: Activity
): CategorizedActivities => {
  return {
    ...result,
    [category]: concat(result[category], [activity]),
  }
}

export const getCategorizedActivities = (
  activities: Activity[],
  projects: ProjectList
): CategorizedActivities => {
  const emptyResult: CategorizedActivities = {
    normalActivities: [],
    reducedActivities: [],
    nonBillableReduced: [],
    nonBillableNormal: [],
    newBusinessActivities: [],
    customerRelationActivities: [],
    marketingActivities: [],
    internalActivities: [],
  }

  return reduce((result: CategorizedActivities, activity: Activity) => {
    const project = projects[activity.project.id]

    // Reduced or normal
    if (isReducedProject(project)) {
      // Is it a billed activity
      if (activity.billed) {
        return addCategory('reducedActivities', result, activity)
      }
      if (activity.billable) {
        return addCategory('reducedActivities', result, activity)
      } else {
        return addCategory('nonBillableReduced', result, activity)
      }
    }

    // Projects by category
    switch (project.custom_properties.Projektkategorie) {
      case 'NewBizz': {
        return addCategory('newBusinessActivities', result, activity)
      }
      case 'Kundenpflege': {
        return addCategory('customerRelationActivities', result, activity)
      }
      case 'Marketing': {
        return addCategory('marketingActivities', result, activity)
      }
      case 'Internes': {
        return addCategory('internalActivities', result, activity)
      }
      case 'Kundenprojekt': {
        if (activity.billable) {
          return addCategory('normalActivities', result, activity)
        } else {
          return addCategory('nonBillableNormal', result, activity)
        }
      }
      default: {
        return result
      }
    }
  }, emptyResult)(activities)
}

export default {
  getActivities,
  getBillableActivities,
  getBillableActivitiesByProjectId,
}
