import { ActivityList } from './modules/activities/types'
import { CompanyList } from './modules/companies/types'
import { ProjectList } from './modules/projects/types'
import { UserList } from './modules/users/types'

const activities: ActivityList = {
  '990677718': {
    id: 990677718,
    date: '2019-01-10',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827650,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990678220': {
    id: 990678220,
    date: '2019-01-11',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827650,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990678231': {
    id: 990678231,
    date: '2019-01-14',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990678232': {
    id: 990678232,
    date: '2019-01-15',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990678234': {
    id: 990678234,
    date: '2019-01-16',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990678235': {
    id: 990678235,
    date: '2019-01-17',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990678238': {
    id: 990678238,
    date: '2019-01-18',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827651,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990678242': {
    id: 990678242,
    date: '2019-02-08',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827651,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990678246': {
    id: 990678246,
    date: '2019-02-07',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990678264': {
    id: 990678264,
    date: '2019-01-21',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990678267': {
    id: 990678267,
    date: '2019-01-22',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827651,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990678270': {
    id: 990678270,
    date: '2019-01-28',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990678273': {
    id: 990678273,
    date: '2019-01-29',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990678274': {
    id: 990678274,
    date: '2019-01-30',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990678275': {
    id: 990678275,
    date: '2019-01-31',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990678315': {
    id: 990678315,
    date: '2019-02-11',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827651,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990678316': {
    id: 990678316,
    date: '2019-02-18',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827651,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990678324': {
    id: 990678324,
    date: '2019-02-01',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990684944': {
    id: 990684944,
    date: '2019-02-04',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990684962': {
    id: 990684962,
    date: '2019-01-08',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827650,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990684965': {
    id: 990684965,
    date: '2019-01-07',
    hours: 2.5,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827650,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990684998': {
    id: 990684998,
    date: '2019-01-21',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990685022': {
    id: 990685022,
    date: '2019-02-08',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990685031': {
    id: 990685031,
    date: '2019-01-28',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990685053': {
    id: 990685053,
    date: '2019-02-18',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827648,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685062': {
    id: 990685062,
    date: '2019-02-26',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685063': {
    id: 990685063,
    date: '2019-02-20',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685065': {
    id: 990685065,
    date: '2019-02-21',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685072': {
    id: 990685072,
    date: '2019-02-22',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685078': {
    id: 990685078,
    date: '2019-02-25',
    hours: 5,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685117': {
    id: 990685117,
    date: '2019-03-01',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827651,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990685122': {
    id: 990685122,
    date: '2019-03-08',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827651,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990685152': {
    id: 990685152,
    date: '2019-01-14',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827648,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990685153': {
    id: 990685153,
    date: '2019-01-18',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827648,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990685185': {
    id: 990685185,
    date: '2019-03-18',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827648,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685188': {
    id: 990685188,
    date: '2019-03-25',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685251': {
    id: 990685251,
    date: '2019-02-28',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685259': {
    id: 990685259,
    date: '2019-02-18',
    hours: 5,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685262': {
    id: 990685262,
    date: '2019-02-15',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827651,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685274': {
    id: 990685274,
    date: '2019-01-23',
    hours: 2.5,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827647,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685340': {
    id: 990685340,
    date: '2019-02-27',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827315,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685347': {
    id: 990685347,
    date: '2019-02-27',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827315,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990685359': {
    id: 990685359,
    date: '2019-02-27',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827315,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990685375': {
    id: 990685375,
    date: '2019-03-07',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827314,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990685385': {
    id: 990685385,
    date: '2019-03-15',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827314,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990685418': {
    id: 990685418,
    date: '2019-03-07',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827314,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685422': {
    id: 990685422,
    date: '2019-03-15',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827314,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685456': {
    id: 990685456,
    date: '2019-03-07',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827314,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990685475': {
    id: 990685475,
    date: '2019-03-13',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827314,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990685542': {
    id: 990685542,
    date: '2019-04-09',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827316,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990685548': {
    id: 990685548,
    date: '2019-04-12',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827314,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990685551': {
    id: 990685551,
    date: '2019-04-16',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827316,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990685565': {
    id: 990685565,
    date: '2019-04-22',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827313,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685568': {
    id: 990685568,
    date: '2019-04-29',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827312,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685572': {
    id: 990685572,
    date: '2019-05-02',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827312,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685573': {
    id: 990685573,
    date: '2019-04-24',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827312,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685578': {
    id: 990685578,
    date: '2019-04-19',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827313,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685668': {
    id: 990685668,
    date: '2019-04-23',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827313,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685671': {
    id: 990685671,
    date: '2019-04-12',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827313,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990685689': {
    id: 990685689,
    date: '2019-04-18',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827313,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685690': {
    id: 990685690,
    date: '2019-04-08',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827313,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685702': {
    id: 990685702,
    date: '2019-04-12',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827313,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685707': {
    id: 990685707,
    date: '2019-04-22',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827312,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685708': {
    id: 990685708,
    date: '2019-04-29',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827312,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685715': {
    id: 990685715,
    date: '2019-04-30',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827312,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685732': {
    id: 990685732,
    date: '2019-04-04',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827314,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685735': {
    id: 990685735,
    date: '2019-04-24',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996046,
    },
    task: {
      id: 3827312,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990685778': {
    id: 990685778,
    date: '2019-01-17',
    hours: 2.5,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827649,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685806': {
    id: 990685806,
    date: '2019-06-03',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825049,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685820': {
    id: 990685820,
    date: '2019-06-10',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685828': {
    id: 990685828,
    date: '2019-06-13',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685831': {
    id: 990685831,
    date: '2019-06-14',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685837': {
    id: 990685837,
    date: '2019-06-21',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685843': {
    id: 990685843,
    date: '2019-06-26',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825049,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685861': {
    id: 990685861,
    date: '2019-06-26',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685886': {
    id: 990685886,
    date: '2019-06-25',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990685895': {
    id: 990685895,
    date: '2019-06-27',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825047,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990687923': {
    id: 990687923,
    date: '2019-06-03',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825049,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990687933': {
    id: 990687933,
    date: '2019-06-10',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990687936': {
    id: 990687936,
    date: '2019-06-14',
    hours: 3.5,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990687947': {
    id: 990687947,
    date: '2019-06-24',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990687950': {
    id: 990687950,
    date: '2019-06-26',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825049,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990687962': {
    id: 990687962,
    date: '2019-06-20',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990687964': {
    id: 990687964,
    date: '2019-06-12',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990687997': {
    id: 990687997,
    date: '2019-05-29',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990687999': {
    id: 990687999,
    date: '2019-05-27',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990688004': {
    id: 990688004,
    date: '2019-05-31',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990688007': {
    id: 990688007,
    date: '2019-05-24',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990688012': {
    id: 990688012,
    date: '2019-05-30',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990688435': {
    id: 990688435,
    date: '2019-06-17',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688438': {
    id: 990688438,
    date: '2019-06-25',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688445': {
    id: 990688445,
    date: '2019-06-21',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688453': {
    id: 990688453,
    date: '2019-06-25',
    hours: 4.5,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825047,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688456': {
    id: 990688456,
    date: '2019-06-26',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825049,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688457': {
    id: 990688457,
    date: '2019-06-24',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825047,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688463': {
    id: 990688463,
    date: '2019-06-28',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688467': {
    id: 990688467,
    date: '2019-06-28',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688469': {
    id: 990688469,
    date: '2019-06-28',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825047,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688475': {
    id: 990688475,
    date: '2019-06-03',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825049,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688483': {
    id: 990688483,
    date: '2019-06-10',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688500': {
    id: 990688500,
    date: '2019-06-14',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688522': {
    id: 990688522,
    date: '2019-06-27',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825047,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990688559': {
    id: 990688559,
    date: '2019-05-27',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990688565': {
    id: 990688565,
    date: '2019-05-28',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990688571': {
    id: 990688571,
    date: '2019-05-30',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990688642': {
    id: 990688642,
    date: '2019-02-26',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825005,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990688644': {
    id: 990688644,
    date: '2019-02-18',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825005,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990688646': {
    id: 990688646,
    date: '2019-02-22',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825005,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990688667': {
    id: 990688667,
    date: '2019-01-21',
    hours: 2.5,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825007,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990688669': {
    id: 990688669,
    date: '2019-01-22',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825007,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990689233': {
    id: 990689233,
    date: '2019-01-09',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996090,
    },
    task: {
      id: 3827651,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990689238': {
    id: 990689238,
    date: '2019-01-07',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827639,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990689325': {
    id: 990689325,
    date: '2019-02-19',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825005,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689336': {
    id: 990689336,
    date: '2019-02-26',
    hours: 2.5,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825005,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689342': {
    id: 990689342,
    date: '2019-03-04',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825007,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689350': {
    id: 990689350,
    date: '2019-04-03',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825003,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689353': {
    id: 990689353,
    date: '2019-03-19',
    hours: 2.5,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825004,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689361': {
    id: 990689361,
    date: '2019-03-18',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825005,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689368': {
    id: 990689368,
    date: '2019-03-18',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825007,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689374': {
    id: 990689374,
    date: '2019-03-22',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825004,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689376': {
    id: 990689376,
    date: '2019-03-27',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825007,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689381': {
    id: 990689381,
    date: '2019-04-01',
    hours: 3.25,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825003,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689383': {
    id: 990689383,
    date: '2019-04-02',
    hours: 1.75,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825003,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990689578': {
    id: 990689578,
    date: '2019-03-05',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825004,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990689582': {
    id: 990689582,
    date: '2019-03-06',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825004,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990689586': {
    id: 990689586,
    date: '2019-03-13',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825004,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990689596': {
    id: 990689596,
    date: '2019-03-14',
    hours: 0.5,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825007,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990689597': {
    id: 990689597,
    date: '2019-03-14',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995787,
    },
    task: {
      id: 3825004,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990690503': {
    id: 990690503,
    date: '2019-05-06',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824980,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990690506': {
    id: 990690506,
    date: '2019-05-15',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824981,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990690510': {
    id: 990690510,
    date: '2019-05-16',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824981,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990690619': {
    id: 990690619,
    date: '2019-05-06',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824980,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990690625': {
    id: 990690625,
    date: '2019-05-15',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824981,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990690628': {
    id: 990690628,
    date: '2019-05-16',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824981,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990690706': {
    id: 990690706,
    date: '2019-05-01',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824982,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990690707': {
    id: 990690707,
    date: '2019-05-01',
    hours: 0,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824982,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990690712': {
    id: 990690712,
    date: '2019-05-10',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824982,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990690727': {
    id: 990690727,
    date: '2019-05-20',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824978,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990690731': {
    id: 990690731,
    date: '2019-05-23',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824982,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990690764': {
    id: 990690764,
    date: '2019-05-20',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824978,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990690767': {
    id: 990690767,
    date: '2019-05-23',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824982,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990690798': {
    id: 990690798,
    date: '2019-05-16',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824981,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990690801': {
    id: 990690801,
    date: '2019-05-06',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824980,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990690838': {
    id: 990690838,
    date: '2019-04-22',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824982,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990690842': {
    id: 990690842,
    date: '2019-04-22',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824980,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990690851': {
    id: 990690851,
    date: '2019-05-20',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824978,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990690864': {
    id: 990690864,
    date: '2019-05-15',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995782,
    },
    task: {
      id: 3824981,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990690971': {
    id: 990690971,
    date: '2019-02-25',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827660,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990690978': {
    id: 990690978,
    date: '2019-03-05',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990690980': {
    id: 990690980,
    date: '2019-03-06',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990690983': {
    id: 990690983,
    date: '2019-03-07',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990690993': {
    id: 990690993,
    date: '2019-03-12',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827660,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990690995': {
    id: 990690995,
    date: '2019-03-15',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990691000': {
    id: 990691000,
    date: '2019-03-18',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827659,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990691008': {
    id: 990691008,
    date: '2019-03-22',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990691010': {
    id: 990691010,
    date: '2019-03-28',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990691011': {
    id: 990691011,
    date: '2019-03-29',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990691012': {
    id: 990691012,
    date: '2019-04-03',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990691014': {
    id: 990691014,
    date: '2019-04-05',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990691021': {
    id: 990691021,
    date: '2019-04-09',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990691022': {
    id: 990691022,
    date: '2019-03-25',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827657,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990691038': {
    id: 990691038,
    date: '2019-03-20',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691047': {
    id: 990691047,
    date: '2019-03-14',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827660,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691060': {
    id: 990691060,
    date: '2019-04-09',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691062': {
    id: 990691062,
    date: '2019-04-12',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827660,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691068': {
    id: 990691068,
    date: '2019-04-08',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827657,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691079': {
    id: 990691079,
    date: '2019-03-18',
    hours: 4.5,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827659,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691084': {
    id: 990691084,
    date: '2019-03-19',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691086': {
    id: 990691086,
    date: '2019-04-02',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691104': {
    id: 990691104,
    date: '2019-03-20',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827660,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691109': {
    id: 990691109,
    date: '2019-03-15',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691129': {
    id: 990691129,
    date: '2019-01-25',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827660,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691149': {
    id: 990691149,
    date: '2019-02-19',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691153': {
    id: 990691153,
    date: '2019-02-18',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827659,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691161': {
    id: 990691161,
    date: '2019-02-13',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827658,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691175': {
    id: 990691175,
    date: '2019-02-27',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827657,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691178': {
    id: 990691178,
    date: '2019-02-28',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827657,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691181': {
    id: 990691181,
    date: '2019-03-01',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691185': {
    id: 990691185,
    date: '2019-03-04',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691190': {
    id: 990691190,
    date: '2019-03-05',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691192': {
    id: 990691192,
    date: '2019-03-07',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827657,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691195': {
    id: 990691195,
    date: '2019-03-08',
    hours: 3.5,
    billed: false,
    billable: true,
    project: {
      id: 944996092,
    },
    task: {
      id: 3827656,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760623434,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691292': {
    id: 990691292,
    date: '2019-01-28',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827639,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691298': {
    id: 990691298,
    date: '2019-02-01',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691324': {
    id: 990691324,
    date: '2019-03-05',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827638,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691331': {
    id: 990691331,
    date: '2019-03-06',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691335': {
    id: 990691335,
    date: '2019-03-07',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691338': {
    id: 990691338,
    date: '2019-03-15',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827639,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691342': {
    id: 990691342,
    date: '2019-03-19',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691343': {
    id: 990691343,
    date: '2019-03-20',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691348': {
    id: 990691348,
    date: '2019-03-22',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827639,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691351': {
    id: 990691351,
    date: '2019-03-26',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827636,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990691415': {
    id: 990691415,
    date: '2019-03-05',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827638,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691423': {
    id: 990691423,
    date: '2019-03-06',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691433': {
    id: 990691433,
    date: '2019-02-25',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691436': {
    id: 990691436,
    date: '2019-02-26',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827638,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691439': {
    id: 990691439,
    date: '2019-03-08',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827636,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691462': {
    id: 990691462,
    date: '2019-03-25',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827639,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691466': {
    id: 990691466,
    date: '2019-03-27',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691467': {
    id: 990691467,
    date: '2019-03-28',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691471': {
    id: 990691471,
    date: '2019-03-29',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691472': {
    id: 990691472,
    date: '2019-04-01',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691475': {
    id: 990691475,
    date: '2019-04-03',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691481': {
    id: 990691481,
    date: '2019-04-04',
    hours: 2.5,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691486': {
    id: 990691486,
    date: '2019-04-05',
    hours: 3.25,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691494': {
    id: 990691494,
    date: '2019-04-10',
    hours: 5,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691497': {
    id: 990691497,
    date: '2019-04-11',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691500': {
    id: 990691500,
    date: '2019-04-15',
    hours: 1.25,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827639,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990691573': {
    id: 990691573,
    date: '2019-03-06',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691612': {
    id: 990691612,
    date: '2019-03-08',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691624': {
    id: 990691624,
    date: '2019-03-29',
    hours: 5,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691625': {
    id: 990691625,
    date: '2019-03-28',
    hours: 5,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691630': {
    id: 990691630,
    date: '2019-03-26',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691636': {
    id: 990691636,
    date: '2019-03-25',
    hours: 4.5,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691648': {
    id: 990691648,
    date: '2019-03-05',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827638,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691698': {
    id: 990691698,
    date: '2019-03-11',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827636,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691701': {
    id: 990691701,
    date: '2019-03-12',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827636,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691704': {
    id: 990691704,
    date: '2019-03-20',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827636,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691708': {
    id: 990691708,
    date: '2019-03-21',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827636,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691715': {
    id: 990691715,
    date: '2019-03-26',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827636,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691720': {
    id: 990691720,
    date: '2019-03-27',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827639,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691726': {
    id: 990691726,
    date: '2019-03-06',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827637,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691740': {
    id: 990691740,
    date: '2019-04-16',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691741': {
    id: 990691741,
    date: '2019-04-17',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691744': {
    id: 990691744,
    date: '2019-04-18',
    hours: 4.5,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827635,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691747': {
    id: 990691747,
    date: '2019-04-22',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827639,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691750': {
    id: 990691750,
    date: '2019-04-23',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827636,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691780': {
    id: 990691780,
    date: '2019-05-06',
    hours: 2.75,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825818,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691785': {
    id: 990691785,
    date: '2019-05-07',
    hours: 2.75,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825816,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691804': {
    id: 990691804,
    date: '2019-05-08',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825816,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691810': {
    id: 990691810,
    date: '2019-05-13',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825818,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691816': {
    id: 990691816,
    date: '2019-05-15',
    hours: 2.5,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825815,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691821': {
    id: 990691821,
    date: '2019-05-16',
    hours: 2.75,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825815,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691823': {
    id: 990691823,
    date: '2019-05-20',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825818,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691828': {
    id: 990691828,
    date: '2019-05-22',
    hours: 5.75,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825814,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691831': {
    id: 990691831,
    date: '2019-05-23',
    hours: 5,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825814,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990691882': {
    id: 990691882,
    date: '2019-05-07',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825816,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691884': {
    id: 990691884,
    date: '2019-05-17',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995872,
    },
    task: {
      id: 3825818,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990691997': {
    id: 990691997,
    date: '2019-01-21',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827603,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692005': {
    id: 990692005,
    date: '2019-01-28',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827602,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692014': {
    id: 990692014,
    date: '2019-01-25',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827601,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692045': {
    id: 990692045,
    date: '2019-01-29',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827601,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692052': {
    id: 990692052,
    date: '2019-01-30',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827601,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692055': {
    id: 990692055,
    date: '2019-01-31',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827603,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692059': {
    id: 990692059,
    date: '2019-02-08',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827600,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692133': {
    id: 990692133,
    date: '2019-01-31',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827603,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692134': {
    id: 990692134,
    date: '2019-02-01',
    hours: 3.5,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827601,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692166': {
    id: 990692166,
    date: '2019-02-19',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827599,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692168': {
    id: 990692168,
    date: '2019-02-20',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827599,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692169': {
    id: 990692169,
    date: '2019-02-21',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827599,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692197': {
    id: 990692197,
    date: '2019-03-04',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827603,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692199': {
    id: 990692199,
    date: '2019-03-08',
    hours: 5,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827599,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692209': {
    id: 990692209,
    date: '2019-03-13',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827599,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692211': {
    id: 990692211,
    date: '2019-03-26',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827599,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692215': {
    id: 990692215,
    date: '2019-04-01',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996078,
    },
    task: {
      id: 3827603,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622096,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692418': {
    id: 990692418,
    date: '2019-01-24',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827634,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692428': {
    id: 990692428,
    date: '2019-02-05',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827632,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692431': {
    id: 990692431,
    date: '2019-01-31',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827632,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692466': {
    id: 990692466,
    date: '2019-02-13',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827634,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692469': {
    id: 990692469,
    date: '2019-02-19',
    hours: 0,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827632,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692478': {
    id: 990692478,
    date: '2019-03-29',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827631,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692483': {
    id: 990692483,
    date: '2019-04-05',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827630,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692489': {
    id: 990692489,
    date: '2019-04-09',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827630,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692546': {
    id: 990692546,
    date: '2019-04-04',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692549': {
    id: 990692549,
    date: '2019-04-05',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692561': {
    id: 990692561,
    date: '2019-02-06',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692564': {
    id: 990692564,
    date: '2019-02-07',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825050,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692583': {
    id: 990692583,
    date: '2019-03-22',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827631,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990692585': {
    id: 990692585,
    date: '2019-03-28',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827631,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990692667': {
    id: 990692667,
    date: '2019-03-29',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827631,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990692673': {
    id: 990692673,
    date: '2019-04-10',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827630,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990692675': {
    id: 990692675,
    date: '2019-04-11',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827630,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990692680': {
    id: 990692680,
    date: '2019-04-10',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827630,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692682': {
    id: 990692682,
    date: '2019-04-11',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827630,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692686': {
    id: 990692686,
    date: '2019-04-12',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827634,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692696': {
    id: 990692696,
    date: '2019-04-15',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944996087,
    },
    task: {
      id: 3827630,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990692793': {
    id: 990692793,
    date: '2019-06-05',
    hours: 5,
    billed: false,
    billable: true,
    project: {
      id: 944995792,
    },
    task: {
      id: 3825064,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692845': {
    id: 990692845,
    date: '2019-03-14',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824926,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692848': {
    id: 990692848,
    date: '2019-03-21',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824925,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692851': {
    id: 990692851,
    date: '2019-03-27',
    hours: 3.5,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824924,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692856': {
    id: 990692856,
    date: '2019-03-25',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824926,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692866': {
    id: 990692866,
    date: '2019-04-15',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824924,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990692894': {
    id: 990692894,
    date: '2019-04-29',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824951,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990692898': {
    id: 990692898,
    date: '2019-05-02',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824949,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990692904': {
    id: 990692904,
    date: '2019-05-10',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824949,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990692907': {
    id: 990692907,
    date: '2019-05-13',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824951,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990692912': {
    id: 990692912,
    date: '2019-05-27',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824947,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990692913': {
    id: 990692913,
    date: '2019-05-29',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824947,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990692914': {
    id: 990692914,
    date: '2019-05-31',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824947,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990692919': {
    id: 990692919,
    date: '2019-06-21',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824951,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990692922': {
    id: 990692922,
    date: '2019-06-27',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824951,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990692969': {
    id: 990692969,
    date: '2019-05-16',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824949,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692971': {
    id: 990692971,
    date: '2019-05-17',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824948,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692975': {
    id: 990692975,
    date: '2019-05-20',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824951,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990692978': {
    id: 990692978,
    date: '2019-05-21',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824948,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990693359': {
    id: 990693359,
    date: '2019-04-17',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824924,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990693365': {
    id: 990693365,
    date: '2019-04-18',
    hours: 1,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824926,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '990693383': {
    id: 990693383,
    date: '2019-05-03',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824923,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990693384': {
    id: 990693384,
    date: '2019-05-02',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824923,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990693387': {
    id: 990693387,
    date: '2019-05-06',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824923,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990693393': {
    id: 990693393,
    date: '2019-05-22',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824922,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990693395': {
    id: 990693395,
    date: '2019-05-23',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824922,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990693396': {
    id: 990693396,
    date: '2019-05-24',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824922,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '990693406': {
    id: 990693406,
    date: '2019-06-06',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824922,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990693412': {
    id: 990693412,
    date: '2019-06-11',
    hours: 6,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824922,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990693415': {
    id: 990693415,
    date: '2019-06-12',
    hours: 0.5,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824926,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990693418': {
    id: 990693418,
    date: '2019-06-20',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824922,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990693421': {
    id: 990693421,
    date: '2019-06-21',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824922,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990693427': {
    id: 990693427,
    date: '2019-06-28',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824926,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990693479': {
    id: 990693479,
    date: '2019-06-07',
    hours: 0.5,
    billed: false,
    billable: true,
    project: {
      id: 944995775,
    },
    task: {
      id: 3824926,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990693624': {
    id: 990693624,
    date: '2019-06-24',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990693625': {
    id: 990693625,
    date: '2019-06-25',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825048,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990693629': {
    id: 990693629,
    date: '2019-06-26',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825047,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990693631': {
    id: 990693631,
    date: '2019-06-27',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825047,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990693632': {
    id: 990693632,
    date: '2019-06-28',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825047,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990693721': {
    id: 990693721,
    date: '2019-06-03',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827638,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '990693735': {
    id: 990693735,
    date: '2019-06-03',
    hours: 3.75,
    billed: false,
    billable: true,
    project: {
      id: 944995790,
    },
    task: {
      id: 3825049,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760621784,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '990693802': {
    id: 990693802,
    date: '2019-04-02',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995792,
    },
    task: {
      id: 3825064,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990693805': {
    id: 990693805,
    date: '2019-04-04',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995792,
    },
    task: {
      id: 3825062,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990693807': {
    id: 990693807,
    date: '2019-04-12',
    hours: 0.5,
    billed: false,
    billable: true,
    project: {
      id: 944995792,
    },
    task: {
      id: 3825064,
      name: 'Koordination',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990693811': {
    id: 990693811,
    date: '2019-04-17',
    hours: 5,
    billed: false,
    billable: true,
    project: {
      id: 944995792,
    },
    task: {
      id: 3825062,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623059,
    },
    hourly_rate: 220,
  },
  '990693824': {
    id: 990693824,
    date: '2019-04-18',
    hours: 4,
    billed: false,
    billable: true,
    project: {
      id: 944995792,
    },
    task: {
      id: 3825062,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '1000': {
    id: 1000,
    date: '2019-04-23',
    hours: 2,
    billed: false,
    billable: false,
    project: {
      id: 944995792,
    },
    task: {
      id: 3825062,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623077,
    },
    hourly_rate: 200,
  },
  '1001': {
    id: 1001,
    date: '2019-04-23',
    hours: 2,
    billed: false,
    billable: true,
    project: {
      id: 944995792,
    },
    task: {
      id: 3825062,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '1002': {
    id: 1002,
    date: '2019-04-23',
    hours: 2,
    billed: false,
    billable: false,
    project: {
      id: 944995792,
    },
    task: {
      id: 3825062,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '1003': {
    id: 1003,
    date: '2019-04-24',
    hours: 3,
    billed: false,
    billable: true,
    project: {
      id: 944995792,
    },
    task: {
      id: 3824923,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623076,
    },
    hourly_rate: 190,
  },
  '1004': {
    id: 1004,
    date: '2019-05-22',
    hours: 3,
    billed: false,
    billable: false,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824923,
      name: 'Design',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '1005': {
    id: 1005,
    date: '2019-05-30',
    hours: 1.5,
    billed: false,
    billable: true,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824922,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '1006': {
    id: 1006,
    date: '2019-05-30',
    hours: 1,
    billed: false,
    billable: false,
    project: {
      id: 944995777,
    },
    task: {
      id: 3824922,
      name: 'Umsetzung',
      billable: true,
    },
    customer: {
      id: 760622092,
    },
    user: {
      id: 933623075,
    },
    hourly_rate: 150,
  },
  '1007': {
    id: 1007,
    date: '2019-06-02',
    hours: 2,
    billed: false,
    billable: false,
    project: {
      id: 944996088,
    },
    task: {
      id: 3827638,
      name: 'Workshop',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
  '1008': {
    id: 1008,
    date: '2019-04-30',
    hours: 2,
    billed: false,
    billable: false,
    project: {
      id: 944996088,
    },
    task: {
      id: 3825062,
      name: 'Konzept',
      billable: true,
    },
    customer: {
      id: 760622093,
    },
    user: {
      id: 933623070,
    },
    hourly_rate: 170,
  },
}

const companies: CompanyList = {
  '760621784': {
    id: 760621784,
    type: 'customer',
    name: 'Beispiel AG',
    website: '',
    email: '',
    phone: '',
    fax: '',
    address: 'Beispiel AG',
    tags: ['Pharma'],
    user: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    labels: ['Pharma'],
    info: '',
    custom_properties: {
      Erwartet: [],
      Zusaetzlich: [],
    },
    created_at: '2019-08-14T14:26:58Z',
    updated_at: '2019-08-15T08:11:36Z',
    identifier: 'K022',
    intern: false,
    billing_tax: 7.7,
    currency: 'CHF',
    default_invoice_due_days: 30,
    projects: [
      {
        id: 944996090,
        identifier: 'P19099',
        name: 'Imagefilm',
        active: false,
        billable: true,
      },
      {
        id: 944995782,
        identifier: 'P19030',
        name: 'Workshop: Community Management',
        active: false,
        billable: true,
      },
      {
        id: 944996046,
        identifier: 'P19066',
        name: 'Lokale Print-Kampagne',
        active: true,
        billable: true,
      },
      {
        id: 944995790,
        identifier: 'P19033',
        name: 'Markenstrategie',
        active: true,
        billable: true,
      },
      {
        id: 944995787,
        identifier: 'P19044',
        name: 'Social Media Kampagne',
        active: true,
        billable: true,
      },
    ],
  },
  '760622092': {
    id: 760622092,
    type: 'customer',
    name: 'Muster GmbH',
    website: '',
    email: '',
    phone: '',
    fax: '',
    address: 'Muster GmbH',
    tags: ['Beratung'],
    user: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    labels: ['Beratung'],
    info: '',
    custom_properties: {
      Erwartet: [],
      Zusaetzlich: [],
    },
    created_at: '2019-08-15T08:12:01Z',
    updated_at: '2019-08-15T08:12:15Z',
    identifier: 'K011',
    intern: false,
    billing_tax: 7.7,
    currency: 'CHF',
    default_invoice_due_days: 30,
    projects: [
      {
        id: 944995777,
        identifier: 'P19120',
        name: 'AdWords-Kampagne',
        active: true,
        billable: true,
      },
      {
        id: 944995775,
        identifier: 'P19101',
        name: 'Redesign Webseite',
        active: true,
        billable: true,
      },
    ],
  },
  '760622093': {
    id: 760622093,
    type: 'customer',
    name: 'Demo-Kunde',
    website: '',
    email: '',
    phone: '',
    fax: '',
    address: 'Demo-Kunde',
    tags: ['Pharma'],
    user: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    labels: ['Pharma'],
    info: '',
    custom_properties: {
      Erwartet: [],
      Zusaetzlich: [],
    },
    created_at: '2019-08-15T08:13:34Z',
    updated_at: '2019-08-15T09:11:24Z',
    identifier: 'K033',
    intern: false,
    billing_tax: 7.7,
    currency: 'CHF',
    default_invoice_due_days: 30,
    projects: [
      {
        id: 944996087,
        identifier: 'P19157',
        name: 'Digitaler Geschäftsbericht',
        active: false,
        billable: true,
      },
      {
        id: 944996088,
        identifier: 'P19133',
        name: 'Corporate Publishing: Unternehmensmagazin',
        active: true,
        billable: true,
      },
      {
        id: 944995792,
        identifier: 'P19078',
        name: 'Neugestaltung Messeauftritt',
        active: true,
        billable: true,
      },
    ],
  },
  '760622096': {
    id: 760622096,
    type: 'customer',
    name: 'Testlich & Partner',
    website: '',
    email: '',
    phone: '',
    fax: '',
    address: 'Testlich & Partner',
    tags: ['Beratung'],
    user: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    labels: ['Beratung'],
    info: '',
    custom_properties: {
      Erwartet: [],
      Zusaetzlich: [],
    },
    created_at: '2019-08-15T08:21:06Z',
    updated_at: '2019-08-15T08:21:22Z',
    identifier: 'K044',
    intern: false,
    billing_tax: 7.7,
    currency: 'CHF',
    default_invoice_due_days: 30,
    projects: [
      {
        id: 944996078,
        identifier: 'P19115',
        name: 'Website: Onpage Suchmaschinen-Optimierung',
        active: true,
        billable: true,
      },
      {
        id: 944995872,
        identifier: 'P19088',
        name: 'Geschäftsdrucksachen',
        active: true,
        billable: true,
      },
    ],
  },
  '760623434': {
    id: 760623434,
    type: 'customer',
    name: 'Prototyp AG',
    website: '',
    email: null,
    phone: '',
    fax: '',
    address: '',
    tags: ['Pharma'],
    user: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    labels: ['Pharma'],
    info: '',
    custom_properties: {
      Erwartet: [],
      Zusaetzlich: [],
    },
    created_at: '2019-08-15T16:02:00Z',
    updated_at: '2019-08-15T16:02:00Z',
    identifier: 'K0090',
    intern: false,
    billing_tax: 7.7,
    currency: 'CHF',
    default_invoice_due_days: 30,
    projects: [
      {
        id: 944996092,
        identifier: 'P19084',
        name: 'TV-Spot',
        active: false,
        billable: true,
      },
    ],
  },
}

const projects: ProjectList = {
  '944995775': {
    id: 944995775,
    identifier: 'P19101',
    name: 'Redesign Webseite',
    active: true,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Muster GmbH',
    budget: 15000,
    hourly_rate: 186,
    info: '',
    tags: ['Digital'],
    labels: ['Digital'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760622092,
      name: 'Muster GmbH',
    },
    tasks: [
      {
        id: 3824926,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3824925,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3824924,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3824923,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3824922,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
    ],
    contracts: [
      {
        id: 460412096,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460412095,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460412094,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460412093,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460412089,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T09:45:45Z',
    updated_at: '2019-08-15T16:41:44Z',
  },
  '944995777': {
    id: 944995777,
    identifier: 'P19120',
    name: 'AdWords-Kampagne',
    active: true,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Muster GmbH',
    budget: 5000,
    hourly_rate: 186,
    info: '',
    tags: ['Digital'],
    labels: ['Digital'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760622092,
      name: 'Muster GmbH',
    },
    tasks: [
      {
        id: 3824951,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3824950,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3824949,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3824948,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3824947,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
    ],
    contracts: [
      {
        id: 460412103,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460412102,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460412101,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460412100,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460412099,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T09:51:45Z',
    updated_at: '2019-08-15T15:50:21Z',
  },
  '944995782': {
    id: 944995782,
    identifier: 'P19030',
    name: 'Workshop: Community Management',
    active: false,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Beispiel AG',
    budget: 15000,
    hourly_rate: 186,
    info: '',
    tags: ['Crossmedial', 'Digital'],
    labels: ['Crossmedial', 'Digital'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760621784,
      name: 'Beispiel AG',
    },
    tasks: [
      {
        id: 3824982,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3824981,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3824980,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3824979,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3824978,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
    ],
    contracts: [
      {
        id: 460414911,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460414910,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460414909,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460414908,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460412113,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T09:56:24Z',
    updated_at: '2019-08-16T14:08:54Z',
  },
  '944995787': {
    id: 944995787,
    identifier: 'P19044',
    name: 'Social Media Kampagne',
    active: true,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Beispiel AG',
    budget: 12000,
    hourly_rate: 186,
    info: '',
    tags: ['Digital'],
    labels: ['Digital'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760621784,
      name: 'Beispiel AG',
    },
    tasks: [
      {
        id: 3825007,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3825006,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3825005,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3825004,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3825003,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
    ],
    contracts: [
      {
        id: 460412129,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460412128,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460412127,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460412126,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460412125,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T10:01:25Z',
    updated_at: '2019-08-15T10:01:25Z',
  },
  '944995790': {
    id: 944995790,
    identifier: 'P19033',
    name: 'Markenstrategie',
    active: true,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Beispiel AG',
    budget: 50000,
    hourly_rate: 186,
    info: '',
    tags: ['Crossmedial'],
    labels: ['Crossmedial'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760621784,
      name: 'Beispiel AG',
    },
    tasks: [
      {
        id: 3825050,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3825049,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3825046,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3825047,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3825048,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
    ],
    contracts: [
      {
        id: 460412138,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460412137,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460412136,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460412135,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460412134,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T10:05:32Z',
    updated_at: '2019-08-15T16:37:54Z',
  },
  '944995792': {
    id: 944995792,
    identifier: 'P19078',
    name: 'Neugestaltung Messeauftritt',
    active: true,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Demo-Kunde',
    budget: 20000,
    hourly_rate: 186,
    info: '',
    tags: ['Crossmedial'],
    labels: ['Crossmedial'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760622093,
      name: 'Demo-Kunde',
    },
    tasks: [
      {
        id: 3825064,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3825063,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3825062,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3825061,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3825060,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
    ],
    contracts: [
      {
        id: 460412140,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
      {
        id: 460412141,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460412143,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460412144,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460412142,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
    ],
    created_at: '2019-08-15T10:08:07Z',
    updated_at: '2019-08-15T16:41:20Z',
  },
  '944995872': {
    id: 944995872,
    identifier: 'P19088',
    name: 'Geschäftsdrucksachen',
    active: true,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Testlich & Partner',
    budget: 5000,
    hourly_rate: 186,
    info: '',
    tags: ['Print'],
    labels: ['Print'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760622096,
      name: 'Testlich & Partner',
    },
    tasks: [
      {
        id: 3825814,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3825815,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3825817,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3825818,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3825816,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
    ],
    contracts: [
      {
        id: 460412443,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
      {
        id: 460413114,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460413113,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460413112,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460413111,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
    ],
    created_at: '2019-08-15T11:36:11Z',
    updated_at: '2019-08-15T16:42:23Z',
  },
  '944996046': {
    id: 944996046,
    identifier: 'P19066',
    name: 'Lokale Print-Kampagne',
    active: true,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Beispiel AG',
    budget: 20000,
    hourly_rate: 186,
    info: '',
    tags: ['Print'],
    labels: ['Print'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760621784,
      name: 'Beispiel AG',
    },
    tasks: [
      {
        id: 3827316,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3827315,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 3827314,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3827313,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3827312,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
    ],
    contracts: [
      {
        id: 460413221,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460413220,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460413219,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460413218,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460413000,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T14:58:43Z',
    updated_at: '2019-08-15T16:38:20Z',
  },
  '944996078': {
    id: 944996078,
    identifier: 'P19115',
    name: 'Website: Onpage Suchmaschinen-Optimierung',
    active: true,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Testlich & Partner',
    budget: 10000,
    hourly_rate: 186,
    info: '',
    tags: ['Digital'],
    labels: ['Digital'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760622096,
      name: 'Testlich & Partner',
    },
    tasks: [
      {
        id: 3827603,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3827602,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
      {
        id: 3827601,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
      {
        id: 3827600,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 3827599,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
    ],
    contracts: [
      {
        id: 460413110,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460413109,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460413108,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460413107,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460413106,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T15:48:22Z',
    updated_at: '2019-08-15T16:42:46Z',
  },
  '944996087': {
    id: 944996087,
    identifier: 'P19157',
    name: 'Digitaler Geschäftsbericht',
    active: false,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Demo-Kunde',
    budget: 10000,
    hourly_rate: 186,
    info: '',
    tags: ['Digital'],
    labels: ['Digital'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760622093,
      name: 'Demo-Kunde',
    },
    tasks: [
      {
        id: 3827634,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3827633,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
      {
        id: 3827632,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
      {
        id: 3827631,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 3827630,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
    ],
    contracts: [
      {
        id: 460413167,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460413166,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460413165,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460413164,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460413163,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T15:53:17Z',
    updated_at: '2019-08-16T15:16:51Z',
  },
  '944996088': {
    id: 944996088,
    identifier: 'P19133',
    name: 'Corporate Publishing: Unternehmensmagazin',
    active: true,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Demo-Kunde',
    budget: 60000,
    hourly_rate: 186,
    info: '',
    tags: ['Print'],
    labels: ['Print'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760622093,
      name: 'Demo-Kunde',
    },
    tasks: [
      {
        id: 3827639,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3827638,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
      {
        id: 3827637,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
      {
        id: 3827636,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 3827635,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
    ],
    contracts: [
      {
        id: 460415010,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460415009,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460415008,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460415007,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460413168,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T15:57:43Z',
    updated_at: '2019-08-15T15:57:43Z',
  },
  '944996090': {
    id: 944996090,
    identifier: 'P19099',
    name: 'Imagefilm',
    active: false,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: 'Beispiel AG',
    budget: 30000,
    hourly_rate: 186,
    info: '',
    tags: ['Film'],
    labels: ['Film'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760621784,
      name: 'Beispiel AG',
    },
    tasks: [
      {
        id: 3827651,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3827650,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
      {
        id: 3827649,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
      {
        id: 3827648,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 3827647,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
    ],
    contracts: [
      {
        id: 460413217,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460413216,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460413215,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460413214,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460413171,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T15:59:34Z',
    updated_at: '2019-08-16T14:08:57Z',
  },
  '944996092': {
    id: 944996092,
    identifier: 'P19084',
    name: 'TV-Spot',
    active: false,
    billable: true,
    fixed_price: true,
    finish_date: null,
    currency: 'CHF',
    billing_variant: 'user',
    billing_address: '',
    budget: 30000,
    hourly_rate: 186,
    info: '',
    tags: ['Film'],
    labels: ['Film'],
    custom_properties: {
      Projektkategorie: 'Kundenprojekt',
      Bewertungskorrektur: {},
      AngebotsReferenz: '',
    },
    leader: {
      id: 933623059,
      firstname: 'Nadja',
      lastname: 'Ziegler',
    },
    customer: {
      id: 760623434,
      name: 'Prototyp AG',
    },
    tasks: [
      {
        id: 3827660,
        name: 'Koordination',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 3827659,
        name: 'Workshop',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
      {
        id: 3827658,
        name: 'Konzept',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
      {
        id: 3827657,
        name: 'Design',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 3827656,
        name: 'Umsetzung',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 180,
      },
    ],
    contracts: [
      {
        id: 460414966,
        user_id: 933623075,
        firstname: 'Sandra',
        lastname: 'Gläser',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 150,
      },
      {
        id: 460414965,
        user_id: 933623070,
        firstname: 'Timo',
        lastname: 'Neu',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 170,
      },
      {
        id: 460414964,
        user_id: 933623077,
        firstname: 'Angela',
        lastname: 'Graf',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 200,
      },
      {
        id: 460414963,
        user_id: 933623076,
        firstname: 'Dustin',
        lastname: 'Dunn',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 190,
      },
      {
        id: 460413175,
        user_id: 933623059,
        firstname: 'Nadja',
        lastname: 'Ziegler',
        billable: true,
        active: true,
        budget: null,
        hourly_rate: 220,
      },
    ],
    created_at: '2019-08-15T16:03:41Z',
    updated_at: '2019-08-16T14:27:05Z',
  },
}

const mockUserCustomerProps = {
  AbsenzenSoll: {},
  Abschlag: {},
  FerienSoll: {},
  JahresSollStd40: {},
  Beschaeftigungsgrad: {},
  InternesSoll: {},
  KundenpflegeSoll: {},
  KundenSoll: {},
  MarketingSoll: {},
  NewBizzSoll: {},
  StundenansatzNormal: {},
  StundenansatzReduziert: {},
  StundenanzahlNormal: {},
  StundenanzahlReduziert: {},

  Eintrittsdatum: undefined,
  Austrittsdatum: undefined,

  isAllowed: true,
}

const users: UserList = {
  '933623059': {
    id: 933623059,
    firstname: 'Nadja',
    lastname: 'Ziegler',
    active: true,
    extern: false,
    email: 'info@wedorganizer.ch',
    mobile_phone: '',
    work_phone: '',
    home_address: '',
    info: '',
    birthday: null,
    avatar_url: null,
    custom_properties: mockUserCustomerProps,
    unit: {
      id: 909162600,
      name: 'Demo-Team',
    },
    created_at: '2019-08-14T14:26:58Z',
    updated_at: '2019-08-16T16:30:19Z',
  },
  '933623070': {
    id: 933623070,
    firstname: 'Timo',
    lastname: 'Neu',
    active: true,
    extern: false,
    email: 'tn@getnada.com',
    mobile_phone: '',
    work_phone: '',
    home_address: '',
    info: '',
    birthday: null,
    avatar_url: null,
    custom_properties: mockUserCustomerProps,
    unit: {
      id: 909162600,
      name: 'Demo-Team',
    },
    created_at: '2019-08-15T07:21:44Z',
    updated_at: '2019-08-15T07:34:47Z',
  },
  '933623075': {
    id: 933623075,
    firstname: 'Sandra',
    lastname: 'Gläser',
    active: true,
    extern: false,
    email: 'sg@getnada.com',
    mobile_phone: '',
    work_phone: '',
    home_address: '',
    info: '',
    birthday: null,
    avatar_url: null,
    custom_properties: mockUserCustomerProps,
    unit: {
      id: 909162636,
      name: 'Test-Team',
    },
    created_at: '2019-08-15T07:36:13Z',
    updated_at: '2019-08-16T16:14:44Z',
  },
  '933623076': {
    id: 933623076,
    firstname: 'Dustin',
    lastname: 'Dunn',
    active: true,
    extern: false,
    email: 'dd@getnada.com',
    mobile_phone: '',
    work_phone: '',
    home_address: '',
    info: '',
    birthday: null,
    avatar_url: null,
    custom_properties: mockUserCustomerProps,
    unit: {
      id: 909162636,
      name: 'Test-Team',
    },
    created_at: '2019-08-15T07:37:32Z',
    updated_at: '2019-08-16T16:14:33Z',
  },
  '933623077': {
    id: 933623077,
    firstname: 'Angela',
    lastname: 'Graf',
    active: true,
    extern: false,
    email: 'ag@getnada.com',
    mobile_phone: '',
    work_phone: '',
    home_address: '',
    info: '',
    birthday: null,
    avatar_url: null,
    custom_properties: mockUserCustomerProps,
    unit: {
      id: 909162600,
      name: 'Demo-Team',
    },
    created_at: '2019-08-15T07:38:41Z',
    updated_at: '2019-08-16T16:30:01Z',
  },
}

type MockData = {
  activities: ActivityList
  companies: CompanyList
  projects: ProjectList
  users: UserList
}

export const getMockData = (): MockData => {
  return {
    activities,
    companies,
    projects,
    users,
  }
}
