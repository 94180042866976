import React from 'react'
import { RouteProps, withRouter } from 'react-router'
import Menu from '@material-ui/icons/Menu'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import cx from 'classnames'

import { HeaderClasses, default as headerStyle, HeaderColor } from './headerStyle'

import { compose } from 'recompose'
import { RoutePath } from '../../page/routes'
import { withToggle, WithToggleProps } from '../Sidebar/withToggle'

type Props = {
  color: HeaderColor
  routes: RoutePath[]
}

type ComponentProps = Props & WithToggleProps & RouteProps & WithStyles<HeaderClasses | HeaderColor>

const Header: React.SFC<ComponentProps> = props => {
  function makeBrand() {
    let name = ''
    props.routes.map((prop, key) => {
      if (prop.path === (props.location ? props.location.pathname : '____')) {
        name = prop.navbarName
      }
      return null
    })
    return name
  }
  const { classes, color } = props

  const appBarClasses = cx({
    [classes.appBar]: true,
    [classes.primary]: color === 'primary',
    [classes.danger]: color === 'danger',
    [classes.success]: color === 'success',
    [classes.info]: color === 'info',
  })
  return (
    <AppBar color={'primary'} className={appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <Button href="#" className={classes.title}>
            {makeBrand()}
          </Button>
        </div>
        {/*<Hidden smDown implementation="css">
          <HeaderLinks />
        </Hidden>*/}
        <Hidden mdUp>
          <IconButton
            className={classes.appResponsive}
            color="inherit"
            aria-label="open drawer"
            onClick={props.toggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default compose<ComponentProps, Props>(
  withToggle,
  withRouter,
  withStyles(headerStyle)
)(Header)
