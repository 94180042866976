/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
import { compose } from 'recompose'
// @material-ui/core components
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Grid, { GridProps } from '@material-ui/core/Grid'

const style = {
  grid: {
    margin: '0 -15px',
    width: 'calc(100% + 30px)',
    // '&:before,&:after':{
    //   display: 'table',
    //   content: '" "',
    // },
    // '&:after':{
    //   clear: 'both',
    // }
  },
}

type GridContainerProps = Omit<GridProps, 'container'>

type GridContainerInjectedProps = WithStyles<typeof style>

const GridContainer: React.SFC<GridContainerProps & GridContainerInjectedProps> = ({
  ...props
}) => {
  const { classes, children, className, ...rest } = props
  return (
    <Grid container {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  )
}
export default compose<
  GridContainerProps & GridContainerInjectedProps,
  Partial<GridContainerProps>
>(withStyles(style))(GridContainer)
