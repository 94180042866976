/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// @material-ui/core components
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
import Grid, { GridProps } from '@material-ui/core/Grid'
import { compose } from 'recompose'
const style = {
  grid: {
    padding: '0 15px !important',
  },
}

type GridItemProps = {
  className?: string
} & Omit<GridProps, 'ref'>

type GridItemInjectedProps = WithStyles<typeof style>

const GridItem: React.FC<GridItemProps & GridItemInjectedProps> = props => {
  const { classes, children, className, ...rest } = props
  return (
    <Grid item {...rest} className={classes.grid + ' ' + className}>
      {children}
    </Grid>
  )
}
export default compose<GridItemProps & GridItemInjectedProps, GridItemProps>(withStyles(style))(
  GridItem
)
