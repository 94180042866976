import React from 'react'
import { FallbackProps } from 'react-error-boundary'
import { routes } from './routes'
import Layout from '../components/Layout/layout'
// import { RouteComponentProps } from 'react-router'
import Typography from '@material-ui/core/Typography'

type ErrorPageProps = {} & FallbackProps
const ErrorPage: React.SFC<ErrorPageProps> = ({ componentStack, error }) => {
  return (
    <Layout routes={routes}>
      <Typography variant={'h4'}>Da ging was schief</Typography>
      <p>
        Bitte meldet euch mit untenstehenden Informationen zur Abklärung bei{' '}
        <a href={'mailto:denis.augsburger@twteam.ch'}>Denis</a>.<br />
        Wir kümmern uns darum eine gute Lösung für das unerwartete Problem zu finden.
      </p>

      <p>
        <b>Fehler: </b>
        {error ? error.toString() : 'Fehler Object leer'}
      </p>
      <p>
        <b>Stack-Trace:</b> {componentStack}
      </p>
    </Layout>
  )
}

export default ErrorPage
