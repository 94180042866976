/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
// @material-ui/icons
// core components
import cardFooterStyle from '../assets/jss/material-dashboard-pro-react/components/cardFooterStyle'
import { compose } from 'recompose'

type CardFooterProps = {
  className: string
  plain?: boolean
  profile?: boolean
  pricing?: boolean
  testimonial?: boolean
  stats?: boolean
  chart?: boolean
  product?: boolean
}

type CardFooterInjectedProps = WithStyles<typeof cardFooterStyle>

const CardFooter: React.SFC<CardFooterProps & CardFooterInjectedProps> = ({ ...props }) => {
  const {
    classes,
    className,
    children,
    plain,
    profile,
    pricing,
    testimonial,
    stats,
    chart,
    product,
    ...rest
  } = props
  const cardFooterClasses = classNames({
    [classes.cardFooter]: true,
    [classes.cardFooterPlain]: plain,
    [classes.cardFooterProfile]: profile || testimonial,
    [classes.cardFooterPricing]: pricing,
    [classes.cardFooterTestimonial]: testimonial,
    [classes.cardFooterStats]: stats,
    [classes.cardFooterChart]: chart || product,
    [className]: className !== undefined,
  })
  return (
    <div className={cardFooterClasses} {...rest}>
      {children}
    </div>
  )
}

export default compose<CardFooterProps & CardFooterInjectedProps, Partial<CardFooterProps>>(
  withStyles(cardFooterStyle)
)(CardFooter)
