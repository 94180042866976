import { filter, indexOf, forEach, reduce } from 'ramda'
import moment from 'moment'
import { RootState } from '../../store'
import { Invoice } from './types'
import { ExtendedProject } from '../projects/selectors'
import {
  getActivitiesOfProject,
  getActivitiesTotal,
  // getActivitiesTotalHour,
  isBillableActivity,
} from '../activities/selectors'
import { Activity } from '../activities/types'
import { Moment } from 'moment'
import { InvoiceStatus } from './types'

export const getInvoices = (state: RootState): { [key: number]: Invoice } => {
  return state.invoices.detailsById
}

export const getInvoicesOfProject = (invoices: Invoice[], projectId: number): Invoice[] => {
  return filter((invoice: Invoice) => {
    return invoice.project_id === projectId
  })(invoices)
}

const relevantStatus: InvoiceStatus[] = ['sent', 'partially_paid', 'paid', 'overdue', 'ignored']

export const getInvoicesTotal = (invoices: Invoice[]): number => {
  return invoices.reduce((amount: number, invoice: Invoice) => {
    if (indexOf(invoice.status, relevantStatus) > -1) {
      return invoice.net_total + amount
    }
    return amount
  }, 0)
}

export const getInvoiceShare = (
  invoices: Invoice[],
  activities: Activity[],
  relevantActivities: Activity[],
  project: { id: number }
): number => {
  const invoicesOfProject = getInvoicesOfProject(invoices, project.id)
  // All Activities of Project
  const activitiesOfProject = getActivitiesOfProject(activities, project.id)
  const billableActivities: Activity[] = filter(isBillableActivity)(activitiesOfProject)
  // const totalHours = getActivitiesTotalHour(billableActivities);
  const totalFee = getActivitiesTotal(billableActivities)

  // FilteredActivities i. e. from user
  const relActivitiesOfProject = getActivitiesOfProject(relevantActivities, project.id)
  const relBillableActivities: Activity[] = filter(isBillableActivity)(relActivitiesOfProject)
  // const relTotalHours = getActivitiesTotalHour(relBillableActivities);
  const relTotalFee = getActivitiesTotal(relBillableActivities)

  const totalAmount = getInvoicesTotal(invoicesOfProject)

  // return (relTotalHours / totalHours * totalAmount);
  if (totalFee > 0) {
    return (relTotalFee / totalFee) * totalAmount
  }
  return 0
}

export const getMostProgressedStatus = (invoices: Invoice[]): InvoiceStatus => {
  let maxStatus = 0
  forEach<Invoice>(invoice => {
    if (maxStatus < relevantStatus.indexOf(invoice.status)) {
      maxStatus = relevantStatus.indexOf(invoice.status)
    }
  })(invoices)
  return relevantStatus[maxStatus]
}

export const getInvoicesBetweenDates = (
  startDate: Moment,
  endDate: Moment,
  invoices: Invoice[]
): Invoice[] => {
  return filter((invoice: Invoice) => {
    const activityDate = moment(invoice.date)
    return activityDate.isBetween(startDate, endDate, 'day', '[]')
  })(invoices)
}

export const getInvoiceMaxDate = (invoices: Invoice[]): Moment => {
  let maxDate = moment('2012-01-01')
  forEach<Invoice>(invoice => {
    if (moment(invoice.date).isAfter(maxDate)) {
      maxDate = moment(invoice.date)
    }
  })(invoices)
  return maxDate
}

export const getInvoicesOfExtendedProjectList: (projects: ExtendedProject[]) => Invoice[] = reduce(
  (invoices: Invoice[], project: ExtendedProject) => {
    return [...invoices, ...project.invoices]
  },
  []
)

export default {
  getInvoices,
}
