/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { WithStyles } from '@material-ui/core/styles'
// @material-ui/icons
// core components
import cardStyle from '../assets/jss/material-dashboard-pro-react/components/cardStyle'
import { compose } from 'recompose'
type CardProps = {
  className: string
  plain?: boolean
  profile?: boolean
  blog?: boolean
  raised?: boolean
  background?: boolean
  pricing?: boolean
  testimonial?: boolean
  color: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose'
  product?: boolean
  chart?: boolean
  login?: boolean
}

type CardInjectedProps = CardProps & WithStyles<typeof cardStyle>

const Card: React.SFC<CardInjectedProps> = ({ ...props }) => {
  const {
    classes,
    className,
    children,
    plain,
    profile,
    blog,
    raised,
    background,
    pricing,
    color,
    product,
    testimonial,
    chart,
    login,
    ...rest
  } = props
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile || testimonial,
    [classes.cardBlog]: blog,
    [classes.cardRaised]: raised,
    [classes.cardBackground]: background,
    [classes.cardPricingColor]:
      (pricing && color !== undefined) || (pricing && background !== undefined),
    [classes[color]]: color,
    [classes.cardPricing]: pricing,
    [classes.cardProduct]: product,
    [classes.cardChart]: chart,
    [classes.cardLogin]: login,
    [className]: className !== undefined,
  })
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  )
}

export default compose<CardInjectedProps, Partial<CardProps>>(withStyles(cardStyle))(Card)
