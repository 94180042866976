import React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { MockRootState } from '../../state/mockStore'
import { OverviewStats } from '../../state/modules/statsMoco/overviewStats'
import { MockStatisticNames } from '../../state/modules/mockGeneral/types'
import { MyPieChart } from './components/MyPieChart'
import DemoFilter from './DemoFilter'
import { Company } from '../../state/modules/companies/types'
import { getCustomerStats } from '../../state/modules/statsMoco/customerStats'
import { diagramGridProps } from './constants'
import CardContainer from './components/CardContainer'
import CardItem from './components/CardItem'
import ZoomIn from '@material-ui/icons/ZoomIn'
//import { MyTreemap } from './components/MyTreemap'
import { MyLineChart } from './components/MyLineChart'
import Info from './components/Info'
import PeriodFilter from './PeriodFilter'
import { getAdditionalInfo, AdditionalFilterInfo } from '../../state/modules/mockGeneral/selectors'

type CustomerDetailsPropsPublic = {
  customer: Company
}

interface CustomerDetailsProps {
  data: OverviewStats
  customer: Company
  info: AdditionalFilterInfo
}

const CustomerDetails: React.SFC<CustomerDetailsProps> = ({ data, customer, info }) => {
  const {
    proportions: { byBillable, byEmployee, byEmployeeUnit, byProject, byTask },
  } = data

  return (
    <React.Fragment>
      <DemoFilter statistic={MockStatisticNames.demo} hideCustomer />

      <CardContainer>
        {/* Unsuitable
        <CardItem title="Kunden" icon={<ZoomIn />} gridProps={diagramGridProps}>
          <MyPieChart data={byCustomer} />
        </CardItem>
        */}

        <CardItem title="Personal" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byEmployee} info={info} />
        </CardItem>

        <CardItem title="Team" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byEmployeeUnit} info={info} />
        </CardItem>

        <CardItem title="Projekte" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byProject} info={info} />
        </CardItem>

        <CardItem
          title="Leistungsgruppen"
          icon={<ZoomIn />}
          gridProps={diagramGridProps}
          info={info}
        >
          <MyPieChart data={byTask} info={info} />
        </CardItem>

        <CardItem title="Verrechenbar" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyPieChart data={byBillable} info={info} />
        </CardItem>
      </CardContainer>

      <CardContainer>
        <Info
          value={`${data.info.cntCompanies}`}
          range="Anzahl gefilterter Kunden"
          title="Anzahl Kunden"
        />
        <Info
          value={`${data.info.cntProjects}`}
          range="Anzahl gefilterter Projekte"
          title="Anzahl Projekte"
        />
        <Info
          value={`${data.info.cntDays}`}
          range="Anzahl Tage mit Stunden für Auswertung"
          title="Anzahl Arbeitstage"
        />
        <Info value={`${data.info.avgFee}`} range="Verrechbares Honorar" title="∅ Honorar" />
      </CardContainer>

      <PeriodFilter statistic={MockStatisticNames.demo} />

      <CardContainer>
        <CardItem title="Verlauf" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyLineChart data={data.progress.byProgressSum} info={info} />
        </CardItem>

        <CardItem title="Auslastung" icon={<ZoomIn />} gridProps={diagramGridProps} info={info}>
          <MyLineChart data={data.progress.byPeriodSum} info={info} />
        </CardItem>
      </CardContainer>
    </React.Fragment>
  )
}

const mapStateToProps = (
  state: MockRootState,
  props: CustomerDetailsPropsPublic
): CustomerDetailsProps => ({
  data: getCustomerStats(state, props.customer),
  customer: props.customer,
  info: getAdditionalInfo(MockStatisticNames.demo, state),
})

export default compose<CustomerDetailsProps, CustomerDetailsPropsPublic>(
  connect<CustomerDetailsProps>(mapStateToProps)
)(CustomerDetails)
