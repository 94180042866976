import { TypeKeys as types } from './constants'
import { StatisticNames } from './types'
import { DataFilter } from '../stats/statFilter'

export interface AddPayload {
  resource: string
  data: {
    [key: number]: any
  }
}

export interface AddAction {
  type: types.ADD_DATA
  payload: AddPayload
}

export interface ToggleSidebarAction {
  type: types.TOGGLE_SIDEBAR
  payload: {}
}

interface ClearAction {
  type: types.CLEAR_ALL
  payload: {
    reason: 'logout' | 'sync'
  }
}

interface ClearReSyncAction {
  type: types.CLEAR_RESYNC
  payload: {
    reason: 'resync'
  }
}

interface ErrorAction {
  type: types.SYNC_ERROR
  payload: {
    error?: string
  }
}

interface AnswerAction {
  type: types.ANSWER_DATA
  payload: {}
}

interface PostPutAction {
  type: types.POST_PUT_DATA
  payload: {}
}

interface RequestAction {
  type: types.REQUEST_DATA
  payload: {}
}

interface UpdateFilter {
  type: types.UPDATE_FILTER
  statistic: StatisticNames
  payload: Partial<DataFilter>
}

export type GeneralActions =
  | AddAction
  | AnswerAction
  | ErrorAction
  | RequestAction
  | ClearAction
  | ClearReSyncAction
  | ToggleSidebarAction
  | PostPutAction
  | UpdateFilter

const addData = (payload: AddPayload): AddAction => ({
  type: types.ADD_DATA,
  payload,
})

const toggle = (payload: {}): ToggleSidebarAction => ({
  type: types.TOGGLE_SIDEBAR,
  payload,
})

const clear = (reason: 'logout' | 'sync'): ClearAction => ({
  type: types.CLEAR_ALL,
  payload: {
    reason,
  },
})

const clearResync = (reason: 'resync'): ClearReSyncAction => ({
  type: types.CLEAR_RESYNC,
  payload: {
    reason,
  },
})

export const updateFilter = (statistic: StatisticNames, filter: Partial<DataFilter>) => ({
  type: types.UPDATE_FILTER,
  statistic,
  payload: {
    ...filter,
  },
})

export default {
  addData,
  toggle,
  clear,
  clearResync,
}
