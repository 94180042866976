import React from 'react'
import { routes } from './routes'
import Layout from '../components/Layout/layout'
import { RouteComponentProps } from 'react-router'
import Typography from '@material-ui/core/Typography'

type NoRightsPageProps = {} & RouteComponentProps<any>

const NoRightsPage: React.StatelessComponent<NoRightsPageProps> = ({ location }) => {
  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'h4'}>Berechtigung fehlt</Typography>
      <p>
        Diesem internen Benutzer wurde keine Berechtigung zur Benutzung des Statistik-Tools erteilt.
        <br />
        Bitte in MOCO unter Personal/ZugangStatistikTool Berechtigung verwalten.
      </p>
    </Layout>
  )
}

export default NoRightsPage
