import React from 'react'
import { connect } from 'react-redux'
import DemoFilter from './DemoFilter'
import { MockStatisticNames } from '../../state/modules/mockGeneral/types'
import { OverviewStats, getOverviewStats } from '../../state/modules/statsMoco/overviewStats'
import { MockRootState } from '../../state/mockStore'
import { ColumnConfiguration, Format, getColumns, ExcelButton, getExport } from '../../util/table'
import moment from 'moment'
import { Activity } from '../../state/modules/activities/types'
import CustomTable from '../CustomTable'
import { find } from 'ramda'
import { Project } from '../../state/modules/projects/types'
import { Company } from '../../state/modules/companies/types'
import { User } from '../../state/modules/users/types'
import CardItem from './components/CardItem'
import ZoomIn from '@material-ui/icons/ZoomIn'
import CardContainer from './components/CardContainer'

type DemoDataProps = {
  data: OverviewStats
}

const DemoData: React.SFC<DemoDataProps> = ({ data }) => {
  const getProject = (id: number) => find<Project>(p => p.id === id)(data.allData.projects)
  const getCompany = (id: number) => find<Company>(c => c.id === id)(data.allData.companies)

  // Activities Table
  const getActivityConfig = (): ColumnConfiguration[] => {
    return [
      {
        id: 'project',
        label: 'Projekt',
        format: Format.name,
        accessor: (activity: Activity) => {
          const project = getProject(activity.project.id)
          return project ? project.name : activity.project.id
        },
      },
      {
        id: 'customer',
        label: 'Kunde',
        format: Format.name,
        accessor: (activity: Activity) => {
          const company = getCompany(activity.customer.id)
          return company ? company.name : activity.customer.id
        },
      },
      {
        id: 'employee',
        label: 'Mitarbeiter ID',
        format: Format.name,
        accessor: (activity: Activity) => activity.user.id,
      },
      {
        id: 'date',
        label: 'Datum',
        format: Format.name,
        accessor: (activity: Activity) =>
          moment(activity.date, 'YYYY-MM-DD')
            .format('DD.MM.YYYY')
            .toString(),
      },
      {
        id: 'hours',
        label: 'Stundenanzahl',
        format: Format.hours,
      },
      {
        id: 'hourly_rate',
        label: 'Stundenansatz',
        format: Format.currency,
      },
      {
        id: 'amount',
        label: 'Betrag',
        format: Format.currency,
        accessor: (activity: Activity) => activity.hourly_rate * activity.hours,
      },
    ]
  }
  const activityColumns = getColumns(getActivityConfig())

  // Project Table
  const getProjectConfig = (): ColumnConfiguration[] => {
    return [
      {
        id: 'name',
        label: 'Projekt',
        format: Format.name,
        accessor: (project: Project) => {
          return project.name
        },
      },
      {
        id: 'customer',
        label: 'Kunde',
        format: Format.name,
        accessor: (project: Project) => {
          const company = getCompany(project.customer.id)
          return company ? company.name : project.customer.id
        },
      },
      {
        id: 'labels',
        label: 'Projekt Labels',
        format: Format.name,
        accessor: (project: Project) => project.labels.join(','),
      },
      {
        id: 'fixed_price',
        label: 'Fix-Preis',
        format: Format.name,
        accessor: (project: Project) => (project.fixed_price ? 'Ja' : 'Nein'),
      },
    ]
  }
  const projectColumns = getColumns(getProjectConfig())

  // Customer
  const getCustomerConfig = (): ColumnConfiguration[] => {
    return [
      {
        id: 'name',
        label: 'Kunde',
        format: Format.name,
      },
      {
        id: 'labels',
        label: 'Kunden-Labels',
        format: Format.name,
        accessor: (company: Company) => {
          return company.labels.join(',')
        },
      },
    ]
  }
  const customerColumns = getColumns(getCustomerConfig())

  const getUserConfig = (): ColumnConfiguration[] => {
    return [
      {
        id: 'active',
        label: 'Aktiv',
        format: Format.name,
        accessor: (user: User) => (user.active ? 'Ja' : 'Nein'),
      },
      {
        id: 'firstname',
        label: 'Vorname',
        format: Format.name,
      },
      {
        id: 'lastname',
        label: 'Nachname',
        format: Format.name,
      },
      {
        id: 'info',
        label: 'Info',
        format: Format.name,
      },
    ]
  }

  const userColumns = getColumns(getUserConfig())

  return (
    <React.Fragment>
      <DemoFilter statistic={MockStatisticNames.demo} hideStrategy />

      <CardContainer>
        <CardItem title="Zeiteinträge" icon={<ZoomIn />} gridProps={{ xs: 12 }}>
          <ExcelButton
            onClick={getExport('Zeiteinträge', getActivityConfig(), data.filteredData.activities)}
          />
          <CustomTable
            data={data.filteredData.activities}
            columns={activityColumns}
            // defaultExpanded={getAllDefaultExpanded(20)}
            // pivotBy={['customer']}
          />
        </CardItem>
      </CardContainer>

      <CardContainer>
        <CardItem title="Projekte" icon={<ZoomIn />} gridProps={{ xs: 12 }}>
          <ExcelButton
            onClick={getExport('Projekte', getProjectConfig(), data.filteredData.activities)}
          />
          <CustomTable data={data.filteredData.projects} columns={projectColumns} />
        </CardItem>
      </CardContainer>

      <CardContainer>
        <CardItem title="Kunden" icon={<ZoomIn />} gridProps={{ xs: 12 }}>
          <ExcelButton
            onClick={getExport('Kunden', getCustomerConfig(), data.filteredData.companies)}
          />
          <CustomTable
            defaultPageSize={5}
            data={data.filteredData.companies}
            columns={customerColumns}
          />
        </CardItem>
      </CardContainer>

      <CardContainer>
        <CardItem title="Personal" icon={<ZoomIn />} gridProps={{ xs: 12 }}>
          <ExcelButton onClick={getExport('Personal', getUserConfig(), data.filteredData.users)} />
          <CustomTable defaultPageSize={5} data={data.filteredData.users} columns={userColumns} />
        </CardItem>
      </CardContainer>
    </React.Fragment>
  )
}

const mapStateToProps = (state: MockRootState): DemoDataProps => ({
  data: getOverviewStats(state),
})

export default connect(mapStateToProps)(DemoData)
