/*!

=========================================================
* Material Dashboard PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// @material-ui/core components
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles'
// @material-ui/icons
// core components
import cardBodyStyle from '../assets/jss/material-dashboard-pro-react/components/cardBodyStyle'
import { compose } from 'recompose'
type CardBodyProps = {
  className: string
  background?: boolean
  plain?: boolean
  formHorizontal?: boolean
  pricing?: boolean
  signup?: boolean
  color?: boolean
  profile?: boolean
  calendar?: boolean
}
type CardBodyInjectedProps = WithStyles<typeof cardBodyStyle>

const CardBody: React.SFC<CardBodyProps & CardBodyInjectedProps> = ({ ...props }) => {
  const {
    classes,
    className,
    children,
    background,
    plain,
    formHorizontal,
    pricing,
    signup,
    color,
    profile,
    calendar,
    ...rest
  } = props
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyBackground]: background,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyFormHorizontal]: formHorizontal,
    [classes.cardPricing]: pricing,
    [classes.cardSignup]: signup,
    [classes.cardBodyColor]: color,
    [classes.cardBodyProfile]: profile,
    [classes.cardBodyCalendar]: calendar,
    [className]: className !== undefined,
  })
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  )
}
export default compose<CardBodyProps & CardBodyInjectedProps, Partial<CardBodyProps>>(
  withStyles(cardBodyStyle)
)(CardBody)
