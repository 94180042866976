import React from 'react'
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts'
import { DIAGRAM_COLORS } from '../constants'
import { createCustomTooltip } from './CustomTooltip'
import { AdditionalFilterInfo } from '../../../state/modules/mockGeneral/selectors'
const placeholder = 'img/placeholder-nodata.png'

export type PieStatsProp = {
  name: string
  value: number
}

type MyPieChartProps = {
  data: PieStatsProp[]
  info: AdditionalFilterInfo
}

export const MyPieChart: React.FC<MyPieChartProps> = props => {
  const { data, info } = props

  const showPlaceholder = data && data.length === 0 ? true : false
  return showPlaceholder ? (
    <img alt="Platzhalter weil keine Daten" src={placeholder} />
  ) : (
    <ResponsiveContainer height={500} width="100%">
      <PieChart>
        <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%">
          {data.map((entry, index) => (
            <Cell key={entry.name} fill={DIAGRAM_COLORS[index % DIAGRAM_COLORS.length]} />
          ))}
        </Pie>
        <Legend
        //wrapperStyle={{ height: '100px' }}
        //layout="horizontal"
        //verticalAlign="bottom"
        //align="left"
        />
        <Tooltip content={createCustomTooltip(info)} />
      </PieChart>
    </ResponsiveContainer>
  )
}
