import React from 'react'
import { connect } from 'react-redux'
import { Circle } from 'rc-progress'
import { branch, ComponentEnhancer, compose, renderComponent } from 'recompose'
import { RootState } from '../state/store'
import { generalSelector } from '../state/modules/index'
import { routes } from './routes'
import Layout from '../components/Layout/layout'
import { RouteComponentProps } from 'react-router'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { withTheme, WithTheme } from '@material-ui/core/styles'
import { formatPercentage } from '../util/format'
import { getConfig } from '../state/sagas/api/config'

type LoaderProps = {
  isLoading: boolean
  count: number
  maxCount: number
}

type ComponentProps = LoaderProps & RouteComponentProps<any> & WithTheme

const Loader: React.SFC<ComponentProps> = props => {
  const config = getConfig()
  const { count, maxCount, location, theme } = props

  const percent = (1 - count / maxCount) * 100

  return (
    <Layout routes={routes} location={location}>
      <React.Fragment>
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
            <Typography variant={'h4'}>{formatPercentage(percent, true)} geladen</Typography>
            <Typography variant={'body1'}>
              Die Applikation wird auf den neusten Stand gebracht. Es werden folgende Einträge
              aktualisiert.
            </Typography>
            <Typography variant={'h6'}>Synchronisation</Typography>
            <ul>
              <li>Projekte</li>
              <li>Rechnungen</li>
              <li>Mitarbeiter</li>
              <li>Zusatzleistungen</li>
              <li>Zeiteinträge</li>
              <li>Ferien & Abwesenheiten</li>
              <li>Firmen</li>
              <li>Kontakte</li>
            </ul>
            <Typography variant={'h6'}>Nach-Synchronisation</Typography>
            <ul>
              <li>Projekte</li>
              <li>Rechnungen</li>
              <li>Zeiteinträge (Der letzten {config.resyncDays} Tage)</li>
            </ul>

            <Typography variant={'body1'}>
              In der Zwischenzeit kann in einem anderen Fenster/Tab weiter gearbeitet werden.
              <br />
              <Button
                href={config.breakUrl}
                target={'_blank'}
                variant={'contained'}
                color={'primary'}
              >
                Tab öffnen
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} md={2} lg={1}>
            <Circle percent={percent} strokeWidth={3} strokeColor={theme.palette.primary.main} />
          </Grid>
        </Grid>
      </React.Fragment>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: generalSelector.isLoading(state),
  count: generalSelector.getCounter(state),
  maxCount: generalSelector.getMaxCounter(state),
})

const showLoader: ComponentEnhancer<any, any> = compose(
  withTheme,
  connect(mapStateToProps),
  branch((props: LoaderProps) => props.isLoading, renderComponent(Loader))
)

export default showLoader
