import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles'
//import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { Project } from '../../state/modules/projects/types'
import { HashLink as Link } from 'react-router-hash-link'

type ProjectSelectionProps = {
  activeProjectId: number
  projects: Project[]
  title: string | null
}

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    link: {
      textDecoration: 'none',
    },
  })

type StyleProps = WithStyles<typeof styles>

const ProjectSelection: React.StatelessComponent<ProjectSelectionProps & StyleProps> = ({
  activeProjectId,
  classes,
  projects,
  title,
}) => {
  return (
    <React.Fragment>
      {title && <Typography variant="h4">{title}</Typography>}
      <List component="nav">
        <Divider />
        {projects.map(p => {
          return (
            <>
              <Link className={classes.link} key={p.id} to={`/project/${p.id}#pagetitle`}>
                <ListItem button disabled={p.id === activeProjectId}>
                  <ListItemIcon>
                    <Avatar className={classes.avatar}>{`${p.name[0]}${p.name[1]}`}</Avatar>
                  </ListItemIcon>
                  <ListItemText inset primary={`${p.name}`} />
                </ListItem>
              </Link>
              <Divider />
            </>
          )
        })}
      </List>
    </React.Fragment>
  )
}

export default compose<ProjectSelectionProps & StyleProps, ProjectSelectionProps>(
  withStyles(styles)
)(ProjectSelection)
