import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { Company } from '../../state/modules/companies/types'
import { companySelector } from '../../state/modules/companies/index'
import { authenticationSelector } from '../../state/modules/authentication'
import Typography from '@material-ui/core/Typography'
import CustomerSelection from '../customer/CustomerSelection'
import CustomerDetails from '../../components/demo/CustomerDetails'

type CustomerDetailsProps = {
  customer: Company
  customers: Company[]
  loggedIn: boolean
}

type ComponentProps = CustomerDetailsProps & RouteComponentProps<{ id: string }>

/**
 * Page for Brutto-Betriebseinnahmen
 */
const CustomerDetailsPage: React.SFC<ComponentProps> = ({
  customers,
  location,
  match,
  customer,
  loggedIn,
}) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'h5'} id="pagetitle">
        {' '}
        Kunde {customer.name}
      </Typography>
      <CustomerDetails customer={customer} />
      <CustomerSelection
        activeCustomerStat={customer.id}
        customers={customers}
        title={'Weitere Kunden'}
      />
    </Layout>
  )
}

const mapStateToProps = (
  state: RootState,
  prop: RouteComponentProps<{ id: string }>
): CustomerDetailsProps => ({
  customer: companySelector.getCompany(state, parseInt(prop.match.params.id, 10)),
  customers: companySelector.getCustomers(state, true),
  loggedIn: authenticationSelector.isAuthenticated(state),
})

export default compose(connect(mapStateToProps))(CustomerDetailsPage)
