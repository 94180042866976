import React from 'react'
import { ContentRenderer, TooltipProps } from 'recharts'
import { AdditionalFilterInfo } from '../../../state/modules/mockGeneral/selectors'

export const createCustomTooltip: (
  info: AdditionalFilterInfo
) => ContentRenderer<TooltipProps> = info => ({ active, payload, label, labelStyle }) => {
  var nf = Intl && Intl.NumberFormat ? new Intl.NumberFormat('de-CH') : undefined

  if (active && payload) {
    const value =
      typeof payload[0].value === 'number'
        ? payload[0].value
        : parseFloat(payload[0].value as string)
    const formattedValue = nf ? nf.format(value) : value

    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
          padding: '1rem',
        }}
      >
        <p className="label" style={{ margin: 0, padding: 0 }}>{`${
          payload[0].name
        }: ${formattedValue} ${info.unit}`}</p>
      </div>
    )
  }

  return null
}
