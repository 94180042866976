import { GridProps } from '@material-ui/core/Grid'

export const diagramGridProps: GridProps = {
  xs: 12,
  md: 6,
  lg: 6,
  xl: 4,
}

export const DIAGRAM_COLORS = [
  '#19718c',
  '#ff6666',
  '#88d8b0',
  '#6a146f',
  '#ffeead',

  '#76dfef',
  '#f209b9',
  '#95f97d',
  '#3d407a',
  '#ffbe72',

  '#c0cbff',
  '#c93867',
  '#cbbeb5',
  '#bf9b30',
]
