import React from 'react'
import { RouteComponentProps } from 'react-router'
import Layout from '../../components/Layout/layout'
import { routes } from '.././routes'
import Typography from '@material-ui/core/Typography'
import { getSortedArrayProjects } from '../../state/modules/projects/selectors'
import { RootState } from '../../state/store'
import { Project } from '../../state/modules/projects/types'
import { connect } from 'react-redux'
import ProjectSelection from './ProjectSelection'

type ComponentProps = RouteComponentProps<any> & {
  projects: Project[]
}

const ProjectOverviewStats: React.StatelessComponent<ComponentProps> = ({ location, projects }) => {
  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'h4'} style={{ marginLeft: '12px' }}>
        Projekte
      </Typography>
      <ProjectSelection projects={projects} activeProjectId={-1} title={null} />
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  projects: getSortedArrayProjects(state),
})

export default connect(mapStateToProps)(ProjectOverviewStats)
