import React from 'react'
import { default as ReactTable, TableProps } from 'react-table'
import { compose } from 'recompose'
import './dashboardPro/assets/css/dashboard.css'
import './custom-table.css'

type PublicProps = {}

type ComponentProps = PublicProps & Partial<TableProps>

const CustomTable: React.StatelessComponent<ComponentProps> = ({ ...props }) => {
  return (
    <ReactTable
      // minRows={3}
      defaultPageSize={10}
      previousText="Vorherige"
      nextText="Nächste"
      loadingText="Laden..."
      noDataText="Keine Daten vorhanden"
      pageText="Seite"
      ofText="von"
      rowsText="Zeilen"
      /*getTdProps={(state, rowInfo, column, instance) => {
        return {
          classes: {
            number:
          }
        };
      }}*/
      showPaginationTop
      showPaginationBottom={false}
      className="-striped -highlight"
      {...props}
    />
  )
}

export default compose<PublicProps, ComponentProps>()(CustomTable)
