import { Middleware } from 'redux'
import { isDoNotTrackEnabled } from '../page/demo/withTracker'

declare global {
  interface Window {
    gtag: any
  }
}

window.gtag = window.gtag || {}

const analyticsMiddleware: Middleware<any, any, any> = store => next => action => {
  if (window.gtag && !isDoNotTrackEnabled()) {
    window.gtag('event', 'action', {
      event_category: action.type,
      event_label: JSON.stringify(
        action && action.payload && Object.keys(action.payload).length < 5 ? action.payload : {}
      ),
    })
  }
  return next(action)
}
export default analyticsMiddleware
