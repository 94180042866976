import React from 'react'
import { RouteComponentProps } from 'react-router'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import Typography from '@material-ui/core/Typography'
import DemoData from '../../components/demo/DemoData'

type ComponentProps = RouteComponentProps<any>

const DemoDataOverviewPage: React.StatelessComponent<ComponentProps> = ({ location }) => {
  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'h5'}>Grunddaten dieser Demo</Typography>
      <DemoData />
    </Layout>
  )
}

export default DemoDataOverviewPage
