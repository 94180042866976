import { ProjectTypeFilter } from '../stats/statFilter'
import { StatsStrategy, StatsDuration } from '../statsMoco/statFilter'

export type MockStatFilter = {
  customerLabels: string[]
  labels: string[]
  startDate: string | null
  endDate: string | null
  projectType: ProjectTypeFilter
  showStatsIn: StatsStrategy
  duration: StatsDuration
}

export enum MockStatisticNames {
  aaList = 'aaList',
  employee = 'employee',
  customer = 'customer',
  demo = 'demo',
}

export enum SyncContext {
  empty = '',
  complete = 'complete',
  partial = 'partial',
}

export interface GeneralState {
  readonly pendingRequests: number
  readonly maxRequests: number
  readonly openSidebar: boolean
  readonly lastSyncData: string
  readonly lastSyncTime: string
  readonly lastReSyncData: string
  readonly lastReSyncTime: string
  readonly syncContext: SyncContext
  readonly error: string
  readonly statFilters: { [key in MockStatisticNames]: MockStatFilter }
}
