import * as H from 'history'
//import CommentIcon from '@material-ui/icons/Comment'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import BusinessIcon from '@material-ui/icons/Business'
import AssignmentIcon from '@material-ui/icons/Assignment'
import GroupIcon from '@material-ui/icons/Group'
import FilterListIcon from '@material-ui/icons/FilterList'
import ViewListIcon from '@material-ui/icons/ViewList'
import MailIcon from '@material-ui/icons/Mail'

export type RoutePath = {
  path: H.Path
  redirect: boolean
  exact?: boolean
  icon: React.ComponentType<SvgIconProps>
  sidebarName: string
  navbarName: string
  isExternal?: boolean
}

export const routes: RoutePath[] = [
  {
    path: '/',
    exact: true,
    icon: BusinessIcon,
    navbarName: '',
    sidebarName: 'Unternehmen',
    redirect: false,
  },
  {
    path: '/project',
    exact: true,
    icon: AssignmentIcon,
    navbarName: '',
    sidebarName: 'Projekte',
    redirect: false,
  },
  {
    path: '/employee',
    exact: true,
    icon: GroupIcon,
    navbarName: '',
    sidebarName: 'Personal',
    redirect: false,
  },
  {
    path: '/customer',
    exact: true,
    icon: ViewListIcon,
    navbarName: '',
    sidebarName: 'Kunden',
    redirect: false,
  },
  {
    path: '/demoData',
    exact: true,
    icon: FilterListIcon,
    navbarName: '',
    sidebarName: 'Demo-Daten',
    redirect: false,
  },
  {
    path: 'https://twteam.ch/kontakt/?utm_source=demoStats',
    icon: MailIcon,
    navbarName: '',
    sidebarName: 'JETZT ANFRAGEN',
    redirect: false,
    isExternal: true,
  },
  /*, {
    path: '/comaticSync',
    exact: true,
    icon: Sync,
    navbarName: '',
    sidebarName: 'Comatic Synchronisation',
    redirect: false,
  }
  {
    path: '/advertizerSync',
    exact: true,
    icon: Sync,
    navbarName: 'Advertizer Synchronisation',
    sidebarName: 'Advertizer Synchronisation',
    redirect: false,
  }*/
]
