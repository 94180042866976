import { createMuiTheme } from '@material-ui/core/styles'
import { Palette } from '@material-ui/core/styles/createPalette'

const palette: Partial<Palette> = {
  primary: {
    contrastText: '#fff',
    main: '#19718C',
    dark: '#19718C',
    light: '#19718C',
  },
}

export const theme = createMuiTheme({
  palette,
  typography: {
    h5: {
      color: palette.primary!.main,
      fontSize: '1.75rem',
      lineHeight: '3rem',
    },
  },
  overrides: {
    MuiCardHeader: {},
  },
})
