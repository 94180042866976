import React, { ChangeEvent } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import { DataFilter, StatsDuration } from '../../state/modules/statsMoco/statFilter'
import { MockStatisticNames } from '../../state/modules/mockGeneral/types'
// import { RootState } from '../../state/store'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getFilter } from '../../state/modules/mockGeneral/selectors'
import { updateFilter } from '../../state/modules/mockGeneral/actions'
import GridContainer from '../dashboardPro/Grid/GridContainer'
import GridItem from '../dashboardPro/Grid/GridItem'
import { MockRootState } from '../../state/mockStore'

const styles = (theme: Theme) =>
  createStyles({
    filterArea: {
      padding: '15px 0',
    },
  })

type PublicProps = {
  statistic: MockStatisticNames
}

type InjectedProps = {
  filter: DataFilter
  changeDuration: (statistic: MockStatisticNames, duration: StatsDuration) => any

  statistic: MockStatisticNames
} & WithStyles<typeof styles>

const PeriodFilter: React.StatelessComponent<InjectedProps> = ({
  changeDuration,
  filter,
  statistic,
  classes,
}) => {
  // Helper functions
  const changeDur = (myStat: MockStatisticNames) => (e: ChangeEvent<unknown>) => {
    changeDuration(myStat, (e.target as HTMLInputElement).value as StatsDuration)
  }

  return (
    <React.Fragment>
      <GridContainer justify="space-between" className={classes.filterArea}>
        <GridItem>
          <FormControl>
            <FormLabel htmlFor="strategy">Periode</FormLabel>
            <RadioGroup
              aria-label="duration"
              name="duration"
              value={filter.duration}
              onChange={changeDur(statistic)}
              row
            >
              {
                // StatsDuration = 'month' | 'day' | 'week' | 'quarter'
              }
              <FormControlLabel
                value="quarter"
                control={<Radio color="primary" />}
                label="Quartal"
                labelPlacement="end"
              />
              <FormControlLabel
                value="month"
                control={<Radio color="primary" />}
                label="Monat"
                labelPlacement="end"
              />
              <FormControlLabel
                value="week"
                control={<Radio color="primary" />}
                label="Woche"
                labelPlacement="end"
              />
              <FormControlLabel
                value="day"
                control={<Radio color="primary" />}
                label="Tag"
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  )
}

const mapStateToProps = (state: MockRootState, props: PublicProps) => ({
  filter: getFilter(state, props.statistic),
  ...props,
})

const mapDispatchToProps = {
  changeDuration: (statistic: MockStatisticNames, duration: StatsDuration) =>
    updateFilter(statistic, {
      duration,
    }),
}

export default compose<InjectedProps, PublicProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(PeriodFilter)
