import { combineReducers, createStore, compose, applyMiddleware } from 'redux'
// import * as reducers from './modules/index';
import {
  activities,
  authentication,
  // comatic,
  companies,
  // contacts,
  // expenses,
  // general,
  // invoices,
  // offers,
  projects,
  // recurringExpenses,
  // schedules,
  users,
} from './modules'
import { GeneralState, SyncContext } from './modules/mockGeneral/types'
import general from './modules/mockGeneral/index'
import { ActivityState } from './modules/activities/types'
// import { AuthenticationState } from './modules/authentication/types'
// import { ComaticState } from './modules/comatic/types'
import { CompanyState } from './modules/companies/types'
// import { ContactState } from './modules/contacts/types'
// import { ExpenseState } from './modules/expenses/types'
// import { InvoiceState } from './modules/invoices/types'
// simport { OfferState } from './modules/offers/types'
import { ProjectState } from './modules/projects/types'
// import { RecurringExpenseState } from './modules/recurringExpenses/types'
// import { ScheduleState } from './modules/schedules/types'
import { UserState } from './modules/users/types'
// import { ActivityAction } from "./modules/activities/actions";
// import mySaga from './sagas/saga'
import { initFilter } from './modules/mockGeneral/reducers'
import { AuthenticationState } from './modules/authentication/types'
import { getMockData } from './mockData'
import analyticsMiddleware from './analyticsMiddleware'

interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: <R>(a: R) => R
}

export type MockRootState = {
  activities: ActivityState
  authentication: AuthenticationState
  companies: CompanyState
  // comatic: ComaticState
  // contacts: ContactState
  // expenses: ExpenseState
  general: GeneralState
  // invoices: InvoiceState
  users: UserState
  // offers: OfferState
  // recurringExpenses: RecurringExpenseState
  // schedules: ScheduleState
  projects: ProjectState
}

// export type AllActions = ActivityAction;

// const composeEnhancers = ((window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose;

const rootReducer = combineReducers<MockRootState>({
  activities,
  authentication,
  // comatic,
  companies,
  // contacts,
  // expenses,
  general,
  // invoices,
  users,
  // offers,
  // recurringExpenses,
  // schedules,
  projects,
  // Add more Reducers from imports here!
})

const mockData = getMockData()

const mockState: MockRootState = {
  activities: {
    detailsById: mockData.activities,
  },
  authentication: {
    token: '',
    userId: -1,
    isAuthenticated: true,
  },
  companies: {
    detailsById: mockData.companies,
  },
  general: {
    pendingRequests: 0,
    maxRequests: 0,
    openSidebar: false,
    lastSyncData: '',
    lastSyncTime: '',
    lastReSyncData: '',
    lastReSyncTime: '',
    syncContext: SyncContext.empty,
    error: '',

    statFilters: {
      aaList: {
        ...initFilter(),
      },
      customer: {
        ...initFilter(),
      },
      employee: {
        ...initFilter(),
      },
      demo: {
        ...initFilter(),
      },
    },
  },
  projects: {
    detailsById: mockData.projects,
  },
  users: {
    detailsById: mockData.users,
  },
}

const composeEnhancers =
  ((window as Window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose

/* eslint-disable no-underscore-dangle */
const store = createStore(
  rootReducer,
  mockState,
  composeEnhancers(applyMiddleware(analyticsMiddleware))
)
/* eslint-enable */

export default store
