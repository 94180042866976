import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import { RootState } from '../../state/store'
import { User } from '../../state/modules/users/types'
import { userSelector } from '../../state/modules/users/index'
import { authenticationSelector } from '../../state/modules/authentication'
import EmployeeSelection from './EmployeeSelection'
import Typography from '@material-ui/core/Typography'

type OverviewEmployeeProps = {
  users: User[]
  // inactiveUsers: User[]
  // externalUsers: User[]
  loggedIn: boolean
}

type ComponentProps = OverviewEmployeeProps & RouteComponentProps<any>

const PeoplePage: React.SFC<ComponentProps> = ({ location, match, users, loggedIn }) => {
  if (!loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'h4'} style={{ marginLeft: '12px' }}>
        Personal
      </Typography>
      <EmployeeSelection activeUserId={0} hideInfo users={users} />
      {/*
      <EmployeeSelection activeUserId={0} users={activeUsers} />
      <Typography variant={'h5'}>Ehemalige Personal</Typography>
      <EmployeeSelection hideInfo={true} activeUserId={0} users={inactiveUsers} />
      <Typography variant={'h5'}>Externe/Zusätzliche Accounts</Typography>
      <EmployeeSelection hideInfo={true} activeUserId={0} users={externalUsers} />
      */}
    </Layout>
  )
}

const mapStateToProps = (state: RootState): OverviewEmployeeProps => ({
  users: userSelector.getUsersArray(state),
  /*activeUsers: userSelector.getActiveInternalUsers(state),
  inactiveUsers: userSelector.getInactiveUsers(state),
  externalUsers: userSelector.getExterneUsers(state),*/
  loggedIn: authenticationSelector.isAuthenticated(state),
})

export default compose(connect(mapStateToProps))(PeoplePage)
