// ##############################
// // // Header styles
// #############################
import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
} from '../../theme/variables'
import { Theme, createStyles } from '@material-ui/core/styles'
import { StyleSheet } from '../../definitions/Styles'

export type HeaderClasses = 'appBar' | 'container' | 'flex' | 'title' | 'appResponsive'

export type HeaderColor = 'primary' | 'info' | 'success' | 'warning' | 'danger'

const headerStyle = (theme: Theme): StyleSheet<HeaderClasses & HeaderColor> =>
  createStyles({
    appBar: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottom: '0',
      marginBottom: '0',
      position: 'absolute',
      width: '100%',
      padding: '5px 0',
      zIndex: 1029,
      color: '#555555',
      border: '0',
      borderRadius: '0px',
      transition: 'all 150ms ease 0s',
      minHeight: '50px',
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    container: {
      ...container,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      minHeight: '50px',
    },
    flex: {
      flex: 1,
    },
    title: {
      ...defaultFont,
      lineHeight: '30px',
      fontSize: '18px',
      borderRadius: '0',
      textTransform: 'none',
      color: 'inherit',
      '&:hover,&:focus': {
        background: 'transparent',
      },
    },
    appResponsive: {
      top: '0',
    },
    primary: {
      backgroundColor: primaryColor,
      color: '#FFFFFF',
    },
    info: {
      backgroundColor: infoColor,
      color: '#FFFFFF',
      ...defaultBoxShadow,
    },
    success: {
      backgroundColor: successColor,
      color: '#FFFFFF',
      ...defaultBoxShadow,
    },
    warning: {
      backgroundColor: warningColor,
      color: '#FFFFFF',
      ...defaultBoxShadow,
    },
    danger: {
      backgroundColor: dangerColor,
      color: '#FFFFFF',
      ...defaultBoxShadow,
    },
  })

export default headerStyle
