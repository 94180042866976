import { MockRootState } from '../../mockStore'
import { compose, curry } from 'ramda'
import {
  stateToArray,
  filterByDataFilter,
  getProportionDiagramData,
  getDataFilter,
  getProgressDiagramData,
  getInitalValue,
  getWorkingDays,
  getTotal,
} from './helper'
import { OverviewStats } from './overviewStats'
import { User } from '../users/types'
import { DataFilter } from './statFilter'
import { round } from 'lodash'

export const getEmployeeStats = (state: MockRootState, user: User): OverviewStats => {
  // Get Filter
  const dataFilter: DataFilter = {
    ...getDataFilter(state.general.statFilters.demo),
  }

  const arrayState = stateToArray(state)

  const filteredData = compose(curry(filterByDataFilter)(dataFilter))({
    ...arrayState,
    users: [user],
  })

  const avgFee = round(
    getTotal('amount', filteredData.activities) / getTotal('hours', filteredData.activities),
    2
  )

  const proportions = getProportionDiagramData(dataFilter.showStatsIn, filteredData)

  // Progress data
  const startValueAccumulated = getInitalValue(dataFilter, state)
  const progress = getProgressDiagramData(dataFilter, startValueAccumulated, filteredData)

  // MOCK DATA
  return {
    allData: {
      activities: arrayState.activities,
      companies: arrayState.companies,
      projects: arrayState.projects,
      users: arrayState.users,
    },
    filteredData,
    proportions,
    progress,
    info: {
      cntCompanies: filteredData.companies.length,
      cntProjects: filteredData.projects.length,
      cntDays: getWorkingDays(filteredData.activities),
      avgFee: isFinite(avgFee) ? avgFee : 0,
    },
  }
}
