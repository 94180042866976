import React from 'react'
import GridItem from '../../dashboardPro/Grid/GridItem'
import Card from '../../dashboardPro/Card/Card'
import CardHeader from '../../dashboardPro/Card/CardHeader'
//import CardIcon from '../../dashboardPro/Card/CardIcon'
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles'
import { GridProps } from '@material-ui/core/Grid'
import { compose } from 'recompose'
import { cardTitle } from '../../dashboardPro/assets/jss/material-dashboard-pro-react'
import CardBody from '../../dashboardPro/Card/CardBody'
import { AdditionalFilterInfo } from '../../../state/modules/mockGeneral/selectors'
import CardText from '../../dashboardPro/Card/CardText'
const styles = createStyles({
  cardHeader: {
    //background: 'linear-gradient(60deg, #ff6666, #ff6666)',
  },
  cardTitle: {
    ...cardTitle,
    color: '#fff',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
})

type CardItemProps = {
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose'
  icon: React.ReactChild
  title: string
  gridProps?: Omit<GridProps, 'ref'>
  info?: AdditionalFilterInfo
}

type CardItemInjectedProps = WithStyles<typeof styles>

const CardItem: React.FC<CardItemProps & CardItemInjectedProps> = ({
  classes,
  children,
  color,
  icon,
  title,
  gridProps,
  info,
}) => {
  return (
    <GridItem {...gridProps}>
      <Card>
        <CardHeader color="primary" text>
          <CardText color="primary">
            {
              //<CardIcon color={color ? color : 'primary'}>{icon}</CardIcon>
            }
            <h4 className={classes.cardTitle}>
              {title}{' '}
              <span style={{ opacity: 0.4 }}>
                {' '}
                {info && info.unit === 'h'
                  ? '(in h)'
                  : info && info.unit === 'CHF / EUR'
                  ? '(in CHF / EUR)'
                  : null}
              </span>
            </h4>
          </CardText>
        </CardHeader>
        <CardBody>{children}</CardBody>
      </Card>
    </GridItem>
  )
}

export default compose<CardItemProps & CardItemInjectedProps, CardItemProps>(withStyles(styles))(
  CardItem
)
