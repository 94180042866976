import React from 'react'
import moment from 'moment'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import { ChangeEvent } from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { createStyles, withStyles, WithStyles, Theme, useTheme } from '@material-ui/core/styles'
import { DataFilter, StatsStrategy } from '../../state/modules/statsMoco/statFilter'
import { MockStatisticNames } from '../../state/modules/mockGeneral/types'
// import { RootState } from '../../state/store'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { getProjectLabels } from '../../state/modules/projects/selectors'
import { getFilter } from '../../state/modules/mockGeneral/selectors'
import { updateFilter } from '../../state/modules/mockGeneral/actions'
import { Moment } from 'moment'
import '../filter/daypickerStyle.css'
import DatePickerCustomInput from '../filter/DatePickerCustomInput'
import { getCompaniesLabels } from '../../state/modules/companies/selectors'
import { ProjectTypeFilter } from '../../state/modules/stats/statFilter'
import GridContainer from '../dashboardPro/Grid/GridContainer'
import GridItem from '../dashboardPro/Grid/GridItem'
import { MockRootState } from '../../state/mockStore'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const styles = (theme: Theme) =>
  createStyles({
    filterArea: {
      background: theme.palette.common.white, // '#fff',
      padding: '15px 0',
    },
  })

type PublicProps = {
  statistic: MockStatisticNames
  disabledStart?: boolean
  hideProject?: boolean
  hideCustomer?: boolean
  hideEmployee?: boolean
  hideStrategy?: boolean
}

type InjectedProps = {
  filter: DataFilter
  changeCustomerLabel: (statistic: MockStatisticNames, label: string) => any
  changeLabel: (statistic: MockStatisticNames, label: string) => any
  changeEndDate: (statistic: MockStatisticNames, date: Moment) => any
  changeStartDate: (statistic: MockStatisticNames, date: Moment) => any
  changeProjectStatus: (statistic: MockStatisticNames, projectType: ProjectTypeFilter) => any
  changeStrategy: (statistic: MockStatisticNames, strategy: StatsStrategy) => any
  // changeProjectType: (statistic: MockStatisticNames, type: string) => any

  customerLabels: string[]
  labels: string[]
  statistic: MockStatisticNames
  disabledStart?: boolean

  hideProject?: boolean
  hideCustomer?: boolean
  hideEmployee?: boolean
  hideStrategy?: boolean
} & WithStyles<typeof styles>

const AllFilter: React.StatelessComponent<InjectedProps> = ({
  changeLabel,
  changeCustomerLabel,
  changeStartDate,
  changeEndDate,
  changeProjectStatus,
  changeStrategy,
  customerLabels,
  filter,
  labels,
  statistic,
  disabledStart,
  hideProject,
  hideCustomer,
  hideEmployee,
  classes,
  hideStrategy,
}) => {
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  // Helper functions
  const change = (myStat: MockStatisticNames) => (e: ChangeEvent<HTMLSelectElement>) =>
    changeLabel(myStat, e.target.value)
  const changeCustLabel = (myStat: MockStatisticNames) => (e: ChangeEvent<HTMLSelectElement>) =>
    changeCustomerLabel(myStat, e.target.value)
  const changeDate = (myStat: MockStatisticNames, start: boolean) => (date: Date) => {
    start ? changeStartDate(myStat, moment(date)) : changeEndDate(myStat, moment(date))
  }
  const changeProject = (myStat: MockStatisticNames) => (e: ChangeEvent<HTMLSelectElement>) => {
    changeProjectStatus(myStat, e.target.value as ProjectTypeFilter)
  }
  const changeStatsIn = (myStat: MockStatisticNames) => (e: ChangeEvent<unknown>) => {
    changeStrategy(myStat, (e.target as HTMLInputElement).value as StatsStrategy)
  }

  return (
    <React.Fragment>
      <GridContainer justify="space-between" className={classes.filterArea}>
        {!hideCustomer && (
          <GridItem>
            <FormControl>
              <InputLabel htmlFor="label-customer-select">Kunden-Label</InputLabel>
              <Select
                value={filter.customerLabels[0] ? filter.customerLabels[0] : ''}
                onChange={changeCustLabel(statistic)}
                inputProps={{
                  name: 'customer-label',
                  id: 'label-customer-select',
                }}
                style={{ width: '140px' }}
              >
                <MenuItem value="" selected={!filter.customerLabels[0]}>
                  Alle
                </MenuItem>
                {customerLabels.map(label => (
                  <MenuItem key={label} value={label} selected={filter.customerLabels[0] === label}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        )}

        {!hideProject && (
          <GridItem>
            <FormControl style={{ paddingRight: '20px' }}>
              <InputLabel htmlFor="label-project-select">Projekt-Label</InputLabel>
              <Select
                value={filter.labels[0] ? filter.labels[0] : ''}
                onChange={change(statistic)}
                inputProps={{
                  name: 'label-project',
                  id: 'label-project-select',
                }}
                style={{ width: '140px' }}
              >
                <MenuItem value="" selected={!filter.labels[0]}>
                  Alle
                </MenuItem>
                {labels.map(label => (
                  <MenuItem key={label} value={label} selected={filter.labels[0] === label}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="label-select">Projekt-Status</InputLabel>
              <Select
                value={filter.projectType}
                onChange={changeProject(statistic)}
                inputProps={{
                  name: 'age',
                  id: 'label-select',
                }}
                style={{ width: '140px' }}
              >
                <MenuItem value={ProjectTypeFilter.all}>Alle Projekte</MenuItem>

                <MenuItem value={ProjectTypeFilter.active}>Aktive Projekte</MenuItem>

                <MenuItem value={ProjectTypeFilter.archived}>Archivierte Projekte</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        )}

        <GridItem>
          <FormControl style={{ paddingRight: '20px' }}>
            <DayPickerInput
              component={DatePickerCustomInput({
                label: 'Von',
              })}
              hideOnDayClick
              format="DD.MM.YYYY"
              formatDate={formatDate}
              parseDate={parseDate}
              placeholder={``}
              dayPickerProps={{
                locale: 'de-ch',
                localeUtils: MomentLocaleUtils,
                modifiers: {
                  disabled: [
                    {
                      before: new Date(2019, 0, 1),
                      after: new Date(2019, 6, 31),
                    },
                  ],
                },
              }}
              onDayChange={changeDate(statistic, true)}
              value={filter.startDate ? filter.startDate.toDate() : undefined}
              inputProps={{
                disabled: !!disabledStart,
              }}
              classNames={{
                container: 'DayPicker',
                overlayWrapper: 'DayPicker-wrapper',
                overlay:
                  (hideCustomer && hideProject && isLarge) || !isLarge
                    ? 'DayPickerInput-Overlay-left'
                    : 'DayPickerInput-Overlay-right',
              }}
            />
          </FormControl>
          <FormControl>
            <DayPickerInput
              component={DatePickerCustomInput({
                label: 'Bis',
              })}
              hideOnDayClick
              format="DD.MM.YYYY"
              formatDate={formatDate}
              parseDate={parseDate}
              placeholder={``}
              dayPickerProps={{
                locale: 'de-ch',
                localeUtils: MomentLocaleUtils,
                modifiers: {
                  disabled: [
                    {
                      before: new Date(2019, 0, 1),
                      after: new Date(2019, 6, 31),
                    },
                  ],
                },
              }}
              onDayChange={changeDate(statistic, false)}
              value={filter.endDate ? filter.endDate.toDate() : undefined}
              classNames={{
                container: 'DayPicker',
                overlayWrapper: 'DayPicker-wrapper',
                overlay:
                  (hideCustomer && hideProject && isLarge) || !isLarge
                    ? 'DayPickerInput-Overlay-left'
                    : 'DayPickerInput-Overlay-right',
              }}
            />
          </FormControl>
        </GridItem>
      </GridContainer>
      <GridContainer justify="space-between">
        {!hideStrategy && (
          <GridItem>
            <FormControl style={{ marginTop: '2rem' }}>
              <FormLabel htmlFor="strategy">Anzeigen in</FormLabel>
              <RadioGroup
                aria-label="strategy"
                name="strategy"
                value={filter.showStatsIn}
                onChange={changeStatsIn(statistic)}
                row
              >
                <FormControlLabel
                  value="amount"
                  control={<Radio color="primary" />}
                  label="CHF / EUR"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="hours"
                  control={<Radio color="primary" />}
                  label="Stunden"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </GridItem>
        )}
      </GridContainer>
    </React.Fragment>
  )
}

const mapStateToProps = (state: MockRootState, props: PublicProps) => ({
  labels: getProjectLabels(state as any),
  customerLabels: getCompaniesLabels(state.companies.detailsById),
  filter: getFilter(state, props.statistic),
  ...props,
})

const mapDispatchToProps = {
  // Todo: Check params
  changeProjectStatus: (statistic: MockStatisticNames, projectType: ProjectTypeFilter) =>
    updateFilter(statistic, { projectType }),
  changeCustomerLabel: (statistic: MockStatisticNames, label: string) =>
    updateFilter(statistic, { customerLabels: [label] }),
  changeLabel: (statistic: MockStatisticNames, label: string) =>
    updateFilter(statistic, { labels: [label] }),
  changeEndDate: (statistic: MockStatisticNames, date: Moment) =>
    updateFilter(statistic, {
      endDate: date,
    }),
  changeStrategy: (statistic: MockStatisticNames, strategy: StatsStrategy) =>
    updateFilter(statistic, {
      showStatsIn: strategy,
    }),
  changeStartDate: (statistic: MockStatisticNames, date: Moment) =>
    updateFilter(statistic, {
      startDate: date,
    }),
}

export default compose<InjectedProps, PublicProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(AllFilter)
