import React from 'react'
import { RouteComponentProps } from 'react-router'
import Layout from '../../components/Layout/layout'
import { routes } from '../routes'
import Typography from '@material-ui/core/Typography'
import OverviewList from '../../components/demo/OverviewList'

type ComponentProps = RouteComponentProps<any>

const CompanyOverviewStats: React.StatelessComponent<ComponentProps> = ({ location }) => {
  return (
    <Layout routes={routes} location={location}>
      <Typography variant={'h5'}>Unternehmen</Typography>
      <OverviewList />
    </Layout>
  )
}

export default CompanyOverviewStats
