import React from 'react'
import { User } from '../../state/modules/users/types'
import Avatar from '@material-ui/core/Avatar'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { createStyles, withStyles, WithStyles, Theme } from '@material-ui/core/styles'
//import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { HashLink as Link } from 'react-router-hash-link'

type EmployeeSelectionProps = {
  activeUserId: number
  hideInfo?: boolean
  users: User[]
}

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    link: {
      textDecoration: 'none',
    },
  })

type StyleProps = WithStyles<typeof styles>

const EmployeeSelection: React.StatelessComponent<EmployeeSelectionProps & StyleProps> = ({
  activeUserId,
  classes,
  hideInfo,
  users,
}) => {
  let info

  if (!hideInfo) {
    info = (
      <React.Fragment>
        <Typography variant={'h5'}>Aktuelle Auswertungen</Typography>
        <Link className={classes.link} to={'/employee/overview'}>
          <ListItem button key={'rekap'} disabled={activeUserId === 1}>
            <ListItemIcon>
              <Avatar className={classes.avatar}>R</Avatar>
            </ListItemIcon>
            <ListItemText>Rekap (Weiteres Personal)</ListItemText>
          </ListItem>
        </Link>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <List component="nav">
        {info}
        <Divider />
        {users.map(u => {
          return (
            <>
              <Link className={classes.link} key={u.id} to={`/employee/${u.id}#pagetitle`}>
                <ListItem button disabled={u.id === activeUserId}>
                  <ListItemIcon>
                    <Avatar className={classes.avatar}>{`${u.firstname[0]}${
                      u.lastname[0]
                    }`}</Avatar>
                  </ListItemIcon>
                  <ListItemText inset primary={`${u.firstname} ${u.lastname}`} />
                </ListItem>
              </Link>
              <Divider />
            </>
          )
        })}
      </List>
    </React.Fragment>
  )
}

export default compose<EmployeeSelectionProps & StyleProps, EmployeeSelectionProps>(
  withStyles(styles)
)(EmployeeSelection)
