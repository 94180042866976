import React from 'react'
import Card from '@material-ui/core/Card'
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core/styles'
import hoverCardStyle from '../../dashboardPro/assets/jss/material-dashboard-pro-react/hoverCardStyle'
import {
  //successColor,
  // tooltip,
  cardTitle,
  grayColor,
} from '../../dashboardPro/assets/jss/material-dashboard-pro-react'
import GridItem from '../../dashboardPro/Grid/GridItem'
import CardHeader from '../../dashboardPro/Card/CardHeader'
import CardIcon from '../../dashboardPro/Card/CardIcon'
import InfoIcon from '@material-ui/icons/InfoTwoTone'
//import ZoomIn from '@material-ui/icons/ZoomIn'
import CardFooter from '../../dashboardPro/Card/CardFooter'
import { compose } from 'recompose'

type InfoProps = {
  title: string
  value: string
  range: string
}

const styles = (theme: Theme) =>
  createStyles({
    grid: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '2rem',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '2rem',
      },
    },
    ...hoverCardStyle,
    // tooltip,
    cardTitle: {
      ...cardTitle,
      marginTop: '0px',
      marginBottom: '3px',
    },
    cardIconTitle: {
      ...cardTitle,
      marginTop: '15px',
      marginBottom: '0px',
    },
    cardCategory: {
      color: grayColor[0],
      fontSize: '14px',
      paddingTop: '10px',
      marginBottom: '0',
      marginTop: '0',
      margin: '0',
    },
    stats: {
      color: grayColor[0],
      fontSize: '12px',
      lineHeight: '22px',
      display: 'inline-flex',
      '& svg': {
        position: 'relative',
        top: '4px',
        width: '16px',
        height: '16px',
        marginRight: '3px',
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        position: 'relative',
        top: '4px',
        fontSize: '16px',
        marginRight: '3px',
      },
    },
  })

type InjectedInfoProps = WithStyles<typeof styles>

const Info: React.FC<InfoProps & InjectedInfoProps> = ({ classes, title, value, range }) => {
  return (
    <GridItem xs={6} sm={3} className={classes.grid}>
      <Card style={{ overflow: 'visible' }}>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <InfoIcon />
          </CardIcon>
          <p className={classes.cardCategory}>{title}</p>
          <h3 className={classes.cardTitle}>{value}</h3>
        </CardHeader>
        <CardFooter stats>
          <div className={classes.stats}>{range}</div>
        </CardFooter>
      </Card>
    </GridItem>
  )
}

export default compose<InjectedInfoProps & InfoProps, InfoProps>(withStyles(styles))(Info)
